// InformesEstadisticos.js
import React, { useEffect, useState } from 'react';
import { Select, Button, message } from 'antd';
import axiosInstance from '../services/axiosConfig';

const { Option } = Select;

function InformesEstadisticos() {
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Cargamos la lista de provincias
        axiosInstance.get('/provincias/')
            .then(resp => {
                setProvinces(resp.data || []);
            })
            .catch(err => {
                console.error('Error al cargar provincias:', err);
                message.error('No se pudieron cargar las provincias');
            });
    }, []);

    const handleGenerateReport = async () => {
        if (!selectedProvince) {
            message.warning('Por favor, selecciona una provincia antes de generar el informe.');
            return;
        }
        setIsLoading(true);
        try {
            // Llamamos al endpoint nuevo del backend
            const response = await axiosInstance.get(
                `/informes-estadisticos/generar_pdf/?provincia_id=${selectedProvince}`,
                { responseType: 'blob' }  // para recibir el PDF como blob
            );
            // Creamos una URL temporal y forzamos la descarga
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);

            // Opcional: abrir en pestaña nueva
            // window.open(fileURL);

            // O forzar descarga
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `Informe_${selectedProvince}.docx`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            message.success('Informe generado correctamente.');
        } catch (error) {
            console.error('Error al generar informe:', error);
            message.error('No se pudo generar el informe');
        }
        setIsLoading(false);
    };

    return (
        <div>
            <h2>Generador de Informes</h2>
            <div style={{ marginBottom: 16 }}>
                <span style={{ marginRight: 8 }}>Seleccionar Provincia:</span>
                <Select
                    style={{ width: 220 }}
                    placeholder="Elige una provincia"
                    value={selectedProvince}
                    onChange={(val) => setSelectedProvince(val)}
                    allowClear
                >
                    {provinces.map(prov => (
                        <Option key={prov.id} value={prov.id}>
                            {prov.nombre}
                        </Option>
                    ))}
                </Select>
            </div>
            <Button type="primary" onClick={handleGenerateReport} loading={isLoading}>
                Generar Informe
            </Button>
        </div>
    );
}

export default InformesEstadisticos;
