// EditarValoracionesModal.js
import React, { useState } from 'react';
import { Modal, Form, DatePicker, Button, message } from 'antd';
import dayjs from 'dayjs';
import axiosInstance from '../services/axiosConfig';

function EditarValoracionesModal({
                                     visible,
                                     onClose,
                                     procedimientoId,
                                     onValoracionesUpdated
                                 }) {
    const [form] = Form.useForm();
    const [subiendo, setSubiendo] = useState(false);

    // Handler para añadir una nueva fecha de valoración
    const handleAddDate = async (values) => {
        if (!values.nueva_fecha) {
            message.error('Selecciona una fecha para añadir');
            return;
        }
        const dateISO = values.nueva_fecha.startOf('day').format('YYYY-MM-DD');
        try {
            setSubiendo(true);
            // POST a un endpoint que cree la Valoracion
            await axiosInstance.post(`/valoraciones/`, {
                procedimiento: procedimientoId,
                fecha_valoracion: dateISO
            });
            message.success('Fecha añadida correctamente.');
            form.resetFields(['nueva_fecha']);

            // Avisamos al padre para que recargue
            onValoracionesUpdated();
        } catch (error) {
            console.error(error);
            message.error('Error al añadir la fecha de valoración.');
        } finally {
            setSubiendo(false);
        }
    };

    // Handler para eliminar una fecha (podrías usar un select con la lista)
    const handleDeleteDate = async (values) => {
        if (!values.fecha_eliminar) {
            message.error('Selecciona una fecha para eliminar');
            return;
        }
        const dateISO = values.fecha_eliminar.startOf('day').format('YYYY-MM-DD');

        try {
            setSubiendo(true);
            // DELETE a un endpoint que elimine esa fecha. Podrías necesitar un ID,
            // o filtrar la Valoracion por (procedimiento, fecha_valoracion).
            await axiosInstance.delete(
                `/valoraciones/borrar_por_fecha/`,
                { data: { procedimiento_id: procedimientoId, fecha_valoracion: dateISO } }
            );

            message.success('Fecha eliminada correctamente.');
            form.resetFields(['fecha_eliminar']);
            onValoracionesUpdated();
        } catch (error) {
            console.error(error);
            message.error('Error al eliminar la fecha de valoración.');
        } finally {
            setSubiendo(false);
        }
    };

    return (
        <Modal
            title="Editar Valoraciones"
            visible={visible}
            onCancel={onClose}
            footer={null} // Usamos botones propios
        >
            <p>Añadir o eliminar fechas de evaluación.</p>
            <Form form={form} layout="vertical">
                {/* Campo para añadir nueva fecha */}
                <Form.Item label="Añadir fecha" name="nueva_fecha">
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                </Form.Item>
                <Button
                    type="primary"
                    loading={subiendo}
                    onClick={() => {
                        const values = form.getFieldsValue();
                        handleAddDate(values);
                    }}
                >
                    Añadir
                </Button>

                <hr style={{ margin: '20px 0' }} />

                {/* Campo para eliminar una fecha
            Podrías usar un <Select> con las valoraciones existentes
            o un <DatePicker> si sabes la fecha exacta
        */}
                <Form.Item label="Eliminar fecha" name="fecha_eliminar">
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                </Form.Item>
                <Button
                    danger
                    loading={subiendo}
                    onClick={() => {
                        const values = form.getFieldsValue();
                        handleDeleteDate(values);
                    }}
                >
                    Eliminar
                </Button>
            </Form>
        </Modal>
    );
}

export default EditarValoracionesModal;
