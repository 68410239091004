import React, { useEffect } from 'react';
import { Form, Input, DatePicker, Select, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../services/axiosConfig';
import dayjs from 'dayjs';

const { Option } = Select;

const FacturaAdminForm = ({ factura, onSuccess }) => {
    const [form] = Form.useForm();
    const [provincias, setProvincias] = React.useState([]);
    const [contratos, setContratos] = React.useState([]);

    useEffect(() => {
        // Cargar provincias y contratos
        axiosInstance
            .get('/provincias/')
            .then((res) => setProvincias(res.data))
            .catch(() => message.error('Error al cargar provincias'));

        axiosInstance
            .get('/contratos-publicos/')
            .then((res) => setContratos(res.data))
            .catch(() => message.error('Error al cargar contratos públicos'));
    }, []);

    useEffect(() => {
        if (factura) {
            // Establece valores iniciales en el formulario
            form.setFieldsValue({
                numero_factura: factura.numero_factura,
                fecha_emision: factura.fecha_emision ? dayjs(factura.fecha_emision) : null,
                estado: factura.estado || 'enviada',
                provincia_id: factura.provincia?.id,
                contrato_publico_id: factura.contrato_publico?.id,
                // El archivo (archivo_factura) y justificantes no se precargan en AntD <Upload>
                // para no forzarlo a mostrar algo ya subido.
            });
        }
    }, [factura, form]);

    const onFinish = async (values) => {
        try {
            // Creamos FormData para PATCH
            const formData = new FormData();

            // Campos simples
            if (values.numero_factura) {
                formData.append('numero_factura', values.numero_factura);
            }
            if (values.fecha_emision) {
                formData.append('fecha_emision', values.fecha_emision.format('YYYY-MM-DD'));
            }
            formData.append('estado', values.estado || 'enviada');

            // provincia y contrato (IDs)
            if (values.provincia_id) {
                formData.append('provincia_id', values.provincia_id);
            }
            if (values.contrato_publico_id) {
                formData.append('contrato_publico_id', values.contrato_publico_id);
            }

            // Archivo principal (usa originFileObj)
            if (values.archivo_factura) {
                formData.append('archivo_factura', values.archivo_factura);
            }

            // Justificantes (múltiples)
            if (values.justificantes) {
                for (let fileObj of values.justificantes) {
                    if (fileObj.originFileObj) {
                        formData.append('justificantes', fileObj.originFileObj);
                    }
                }
            }

            // Distinguimos create vs update
            if (factura && factura.id) {
                // PATCH
                await axiosInstance.patch(`/facturas-administracion/${factura.id}/`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    transformRequest: [
                        (data, headers) => data, // Evita convertir FormData a JSON
                    ],
                });
            } else {
                // POST (caso de crear una nueva)
                await axiosInstance.post(`/facturas-administracion/`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    transformRequest: [
                        (data, headers) => data,
                    ],
                });
            }

            message.success('Factura guardada correctamente');
            onSuccess?.();
        } catch (error) {
            console.error('Error al guardar la factura:', error);
            if (error.response?.data?.error) {
                message.error(error.response.data.error);
            } else {
                message.error('Error al guardar la factura');
            }
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
                name="numero_factura"
                label="Número de Factura"
                rules={[{ required: true, message: 'Campo requerido' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="fecha_emision"
                label="Fecha de Emisión"
                rules={[{ required: true, message: 'Campo requerido' }]}
            >
                <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                name="archivo_factura"
                label="Archivo de Factura"
                valuePropName="archivo_factura"
                // Devuelve 'originFileObj' para el principal
                getValueFromEvent={(e) => {
                    if (Array.isArray(e)) return e;
                    return e && e.fileList.length ? e.fileList[0].originFileObj : null;
                }}
            >
                <Upload beforeUpload={() => false} maxCount={1}>
                    <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
                </Upload>
            </Form.Item>

            <Form.Item
                name="provincia_id"
                label="Provincia"
                rules={[{ required: true, message: 'Selecciona una provincia' }]}
            >
                <Select placeholder="Selecciona una provincia">
                    {provincias.map((p) => (
                        <Option key={p.id} value={p.id}>
                            {p.nombre}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="contrato_publico_id"
                label="Contrato Público"
                rules={[{ required: true, message: 'Selecciona un contrato público' }]}
            >
                <Select placeholder="Selecciona un contrato público">
                    {contratos.map((c) => (
                        <Option key={c.id} value={c.id}>
                            {c.numero_contrato} - {c.descripcion}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="estado"
                label="Estado"
                rules={[{ required: true, message: 'Selecciona un estado' }]}
            >
                <Select>
                    <Option value="enviada">Enviada</Option>
                    <Option value="pagada">Pagada</Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="justificantes"
                label="Justificantes (opcionales)"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
            >
                <Upload multiple beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />}>Subir Justificantes</Button>
                </Upload>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Guardar
                </Button>
            </Form.Item>
        </Form>
    );
};

export default FacturaAdminForm;
