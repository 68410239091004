import React, { useEffect, useState } from 'react';
import { Calendar, Badge, message, Tooltip, Modal, ConfigProvider, Select } from 'antd';
import esES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import axiosInstance from '../services/axiosConfig';
import ExpedienteDetail from './ExpedienteDetail';
import './Plataforma.css';

const { Option } = Select;

function CalendarioPage() {
    const [valoraciones, setValoraciones] = useState([]);
    const [showModalExpediente, setShowModalExpediente] = useState(false);
    const [selectedExpedienteId, setSelectedExpedienteId] = useState(null);

    const [userRole, setUserRole] = useState('');
    const [provincias, setProvincias] = useState([]);
    const [selectedProv, setSelectedProv] = useState(null);

    // Estado para saber en qué año y mes está posicionado el calendario
    const [viewYearMonth, setViewYearMonth] = useState(dayjs());

    useEffect(() => {
        axiosInstance.get('/mi_usuario/')
            .then((resp) => setUserRole(resp.data.role.trim()))
            .catch(err => console.error('Error al obtener rol:', err));
    }, []);

    useEffect(() => {
        axiosInstance.get('/provincias/')
            .then((resp) => setProvincias(resp.data))
            .catch(err => console.error('Error al cargar provincias:', err));
    }, []);

    // Cargar valoraciones cuando cambie la provincia o cuando cambie (año, mes)
    useEffect(() => {
        const year = viewYearMonth.year();
        const month = viewYearMonth.month() + 1; // .month() es 0-based

        const params = {
            year,
            month,
            // Si hay selectedProv, lo pasamos
            ...(selectedProv ? { provincia_id: selectedProv } : {})
        };

        axiosInstance.get('procedimientos/calendar_valoraciones/', { params })
            .then(resp => setValoraciones(resp.data))
            .catch(err => {
                console.error('Error al cargar valoraciones:', err);
                message.error('No se pudieron cargar las fechas de valoración');
            });
    }, [selectedProv, viewYearMonth]);

    // Ajustar altura de celdas cada vez que cambian valoraciones o el mes/año
    useEffect(() => {
        const recalcHeights = () => {
            const dayCells = document.querySelectorAll('.enlarged-calendar td .ant-picker-calendar-date');
            if (!dayCells || dayCells.length === 0) return;

            let maxHeight = 0;
            dayCells.forEach(cell => {
                const h = cell.offsetHeight;
                if (h > maxHeight) maxHeight = h;
            });
            dayCells.forEach(cell => {
                cell.style.minHeight = `${maxHeight}px`;
            });
        };

        // Un pequeño retraso para que el DOM termine de renderizar el nuevo mes
        const timer = setTimeout(() => {
            recalcHeights();
        }, 100);

        return () => clearTimeout(timer);
    }, [valoraciones, viewYearMonth]);

    const eventosPorDia = valoraciones.reduce((acc, val) => {
        const fecha = val.fecha_valoracion; // "YYYY-MM-DD"
        if (!acc[fecha]) acc[fecha] = [];

        let badgeType = 'default';
        const procEstado = val.procedimiento?.estado;
        if (['informe_entregado', 'informe_descargado'].includes(procEstado)) {
            badgeType = 'success';
        } else if (['solicitado', 'asignado_peritos'].includes(procEstado)) {
            badgeType = 'warning';
        } else if (['anulado', 'incomparecencia'].includes(procEstado)) {
            badgeType = 'error';
        }

        acc[fecha].push({
            type: badgeType,
            info: {
                id: val.procedimiento?.id,
                estado: procEstado,
                juzgado: val.procedimiento?.juzgado_nombre,
                numProc: val.procedimiento?.numero_procedimiento,
                peritos: val.procedimiento?.peritos_asignados_nombres,
            },
        });
        return acc;
    }, {});

    const handleBadgeClick = (expId) => {
        if (!expId) return;
        setSelectedExpedienteId(expId);
        setShowModalExpediente(true);
    };

    // Se dispara al cambiar de mes/año en el calendario
    const onPanelChange = (value, mode) => {
        // 'value' es un dayjs con el primer día del mes/año que se está mostrando
        setViewYearMonth(value);
    };

    const dateCellRender = (current) => {
        const fechaStr = current.format('YYYY-MM-DD');
        const listData = eventosPorDia[fechaStr] || [];

        return (
            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                {listData.map((item, idx) => {
                    let content = `ID ${item.info.id ?? ''}`;

                    if (userRole === 'Admin' && item.info.peritos) {
                        const shortNames = item.info.peritos
                            .split(',')
                            .map(nombre => nombre.trim().substring(0, 3))
                            .join(' - ');
                        content += ` (${shortNames})`;
                    }

                    return (
                        <li key={idx} style={{ marginBottom: '4px' }}>
                            <Tooltip
                                title={
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                        <strong>ID: {item.info.id ?? ''}{'\n'}</strong>
                                        Estado: {item.info.estado ?? ''}{'\n'}
                                        Fecha: {current.format('DD-MM-YYYY')}{'\n'}
                                        {item.info.juzgado}{'\n'}
                                        {item.info.numProc}{'\n'}
                                        <strong>Peritos: </strong>{item.info.peritos}
                                    </div>
                                }
                            >
                                <Badge
                                    status={item.type}
                                    text={content}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleBadgeClick(item.info.id)}
                                />
                            </Tooltip>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <ConfigProvider locale={esES}>
            <div style={{ background: '#fff', padding: '1rem' }}>
                <h2>Calendario de Valoraciones</h2>

                <div style={{ marginBottom: 16 }}>
                    <label style={{ marginRight: 8 }}>Filtrar por Provincia:</label>
                    <Select
                        allowClear
                        style={{ width: 220 }}
                        placeholder="Selecciona una provincia"
                        value={selectedProv}
                        onChange={value => setSelectedProv(value || null)}
                    >
                        {provincias.map(prov => (
                            <Option key={prov.id} value={prov.id}>
                                {prov.nombre}
                            </Option>
                        ))}
                    </Select>
                </div>

                <Calendar
                    className="enlarged-calendar"
                    dateCellRender={dateCellRender}
                    onPanelChange={onPanelChange}      // Se dispara al cambiar de mes/año
                    onChange={onPanelChange}           // Para soportar también el click de flechas
                />

                <h5>
                    NOTA: Este calendario es a título informativo. Es responsabilidad del perito
                    verificar las fechas de las valoraciones y notificar cualquier discrepancia
                    en el canal de incidencias del procedimiento.
                </h5>

                <Modal
                    visible={showModalExpediente}
                    onCancel={() => setShowModalExpediente(false)}
                    footer={null}
                    width="80%"
                    style={{ top: 20 }}
                    destroyOnClose
                >
                    {selectedExpedienteId && (
                        <ExpedienteDetail procedimientoId={selectedExpedienteId} />
                    )}
                </Modal>
            </div>
        </ConfigProvider>
    );
}

export default CalendarioPage;
