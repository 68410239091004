// FacturaAdminCreate.js

import React, { useState, useEffect } from 'react';
import { Card, Form, Input, DatePicker, Select, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../services/axiosConfig';

const { Option } = Select;

const FacturaAdminCreate = () => {
    const [form] = Form.useForm();
    const [provincias, setProvincias] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [procedimientos, setProcedimientos] = useState([]);

    // Cargamos provincias y contratos
    useEffect(() => {
        axiosInstance
            .get('/provincias/')
            .then((res) => setProvincias(res.data))
            .catch(() => message.error('Error al cargar provincias'));

        axiosInstance
            .get('/contratos-publicos/')
            .then((res) => setContratos(res.data))
            .catch(() => message.error('Error al cargar contratos públicos'));
    }, []);

    // Función para cargar procedimientos pendientes según la provincia
    const fetchPendientesPorProvincia = async (provId) => {
        if (!provId) {
            setProcedimientos([]);
            return;
        }
        try {
            const url = `/facturas-administracion/pendientes_para_factura/?provincia_id=${provId}`;
            const resp = await axiosInstance.get(url);
            setProcedimientos(resp.data);
        } catch (error) {
            message.error('Error al cargar procedimientos pendientes de facturar');
        }
    };

    // Manejador cuando cambia la provincia en el formulario
    const handleProvinceChange = (provId) => {
        form.setFieldsValue({ procedimientos: [] });
        fetchPendientesPorProvincia(provId);
    };

    const onFinish = async (values) => {
        try {
            // Construimos el FormData
            const formData = new FormData();
            formData.append('numero_factura', values.numero_factura);
            formData.append('fecha_emision', values.fecha_emision.format('YYYY-MM-DD'));
            formData.append('provincia_id', values.provincia_id);
            formData.append('contrato_publico_id', values.contrato_publico_id);
            formData.append('estado', values.estado || 'enviada');

            // Añadimos cada procedimiento (son varios)
            values.procedimientos.forEach((id) => {
                formData.append('procedimientos', id);
            });

            // Archivo principal
            if (values.archivo_factura) {
                formData.append('archivo_factura', values.archivo_factura);
            }

            // Justificantes
            if (values.justificantes) {
                for (let fileObj of values.justificantes) {
                    if (fileObj.originFileObj) {
                        formData.append('justificantes', fileObj.originFileObj);
                    }
                }
            }

            // Llamada POST:
            // 1) Sobrescribimos 'Content-Type' a 'multipart/form-data'.
            // 2) Usamos 'transformRequest' para que NO convierta el FormData a JSON.
            await axiosInstance.post('/facturas-administracion/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                transformRequest: [
                    (data, headers) => {
                        // Dejamos el FormData tal cual
                        return data;
                    }
                ]
            });

            message.success('Factura creada con éxito');
            form.resetFields();

        } catch (error) {
            console.error('Error al crear la factura', error);
            if (error.response && error.response.data) {
                message.error(error.response.data.error || 'Error al crear la factura');
            } else {
                message.error('Error al crear la factura');
            }
        }
    };

    return (
        <Card title="Crear Nueva Factura de Administración">
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="numero_factura"
                    label="Número de Factura"
                    rules={[{ required: true, message: 'Campo requerido' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="fecha_emision"
                    label="Fecha de Emisión"
                    rules={[{ required: true, message: 'Selecciona la fecha de emisión' }]}
                >
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    name="provincia_id"
                    label="Provincia"
                    rules={[{ required: true, message: 'Selecciona la provincia' }]}
                >
                    <Select placeholder="Selecciona una provincia" onChange={handleProvinceChange}>
                        {provincias.map((p) => (
                            <Option key={p.id} value={p.id}>
                                {p.nombre}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="contrato_publico_id"
                    label="Contrato Público"
                    rules={[{ required: true, message: 'Selecciona un contrato público' }]}
                >
                    <Select placeholder="Selecciona un contrato público">
                        {contratos.map((c) => (
                            <Option key={c.id} value={c.id}>
                                {c.numero_contrato} - {c.descripcion}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="procedimientos"
                    label="Procedimientos a Facturar"
                    rules={[{ required: true, message: 'Selecciona al menos un procedimiento' }]}
                >
                    <Select mode="multiple" placeholder="Selecciona procedimientos" allowClear>
                        {procedimientos.map((proc) => (
                            <Option key={proc.id} value={proc.id}>
                                {`ID ${proc.id} - ${proc.numero_procedimiento} - ${proc.juzgado_nombre}`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="archivo_factura"
                    label="Archivo de Factura (PDF...)"
                    valuePropName="archivo_factura"
                    // Devolvemos directamente el File real
                    getValueFromEvent={(e) => {
                        if (Array.isArray(e)) return e;
                        return e && e.fileList.length ? e.fileList[0].originFileObj : null;
                    }}
                >
                    <Upload beforeUpload={() => false} maxCount={1}>
                        <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
                    </Upload>
                </Form.Item>


                <Form.Item name="estado" label="Estado" initialValue="enviada">
                    <Select>
                        <Option value="enviada">Enviada</Option>
                        <Option value="pagada">Pagada</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="justificantes"
                    label="Justificantes (opcionales)"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e && e.fileList}
                >
                    <Upload multiple beforeUpload={() => false}>
                        <Button icon={<UploadOutlined />}>Subir Justificantes</Button>
                    </Upload>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Crear Factura
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default FacturaAdminCreate;
