// src/components/PrivatePortalDashboard.js

import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Card, Button, List, Modal, Typography, Table } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import axiosInstance from '../services/axiosConfig';
import ExpedienteDetail from './ExpedienteDetail';

const { Content } = Layout;
const { Title, Text } = Typography;

function PrivatePortalDashboard({ onGoCalendario }) {
    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState({
        upcomingValuationsCount: 0,
        pendingDeliveryCount: 0,
        pendingQualityCount: 0,
        pendingBillingCount: 0,
        nextValuationDates: [],
        monthValuationsCount: 0,
        monthDeliveredCount: 0,
        monthIncomparecenciaCount: 0,
        totalDeliveredCount: 0,
    });
    const [valoraciones, setValoraciones] = useState([]);

    const [showModalExpediente, setShowModalExpediente] = useState(false);
    const [selectedExpedienteId, setSelectedExpedienteId] = useState(null);

    useEffect(() => {
        fetchStats();
        fetchCalendarValuations();
    }, []);

    const fetchStats = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/procedimientos/portal_dashboard_stats/');
            setStats(response.data);
        } catch (error) {
            console.error('Error al obtener estadísticas del Portal Privado:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCalendarValuations = async () => {
        try {
            const resp = await axiosInstance.get('/procedimientos/calendar_valoraciones/');
            setValoraciones(resp.data);
        } catch (error) {
            console.error('Error al cargar valoraciones:', error);
        }
    };

    // Fecha de hoy en formato YYYY-MM-DD
    const todayStr = dayjs().format('YYYY-MM-DD');

    // Valuaciones de HOY
    const valuationsToday = valoraciones.filter(
        (v) => v.fecha_valoracion === todayStr
    );
    let proceduresToday = [];
    if (valuationsToday.length > 0) {
        const mapProcs = {};
        valuationsToday.forEach((val) => {
            const p = val.procedimiento;
            if (p) mapProcs[p.id] = p;
        });
        proceduresToday = Object.values(mapProcs);
    }

    // Fecha más cercana (la primera en nextValuationDates)
    const nextClosestDate = stats.nextValuationDates.length > 0
        ? stats.nextValuationDates[0]
        : null;

    // Procedimientos del día más cercano
    let nextDateProcedures = [];
    if (nextClosestDate) {
        const procsThatDay = valoraciones.filter(
            (v) => v.fecha_valoracion === nextClosestDate
        );
        const uniqueMap = {};
        procsThatDay.forEach((val) => {
            const p = val.procedimiento;
            if (p) uniqueMap[p.id] = p;
        });
        nextDateProcedures = Object.values(uniqueMap);
    }

    const openExpedienteModal = (expId) => {
        setSelectedExpedienteId(expId);
        setShowModalExpediente(true);
    };

    const formatSpanishDate = (dateStr) => {
        dayjs.locale('es');
        return dayjs(dateStr).format('dddd, D [de] MMMM [de] YYYY');
    };

    const columnsToday = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '15%'
        },
        {
            title: 'Juzgado',
            dataIndex: 'juzgado_nombre',
            key: 'juzgado_nombre',
            width: '35%'
        },
        {
            title: 'N.º Procedimiento',
            dataIndex: 'numero_procedimiento',
            key: 'numero_procedimiento',
            width: '35%'
        },
        {
            title: 'Acción',
            key: 'accion',
            render: (record) => (
                <Button type="link" onClick={() => openExpedienteModal(record.id)}>
                    Ver Detalles
                </Button>
            ),
            width: '15%'
        },
    ];

    const columnsNextDay = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '15%'
        },
        {
            title: 'Juzgado',
            dataIndex: 'juzgado_nombre',
            key: 'juzgado_nombre',
            width: '35%'
        },
        {
            title: 'N.º Procedimiento',
            dataIndex: 'numero_procedimiento',
            key: 'numero_procedimiento',
            width: '35%'
        },
        {
            title: 'Acción',
            key: 'accion',
            render: (record) => (
                <Button type="link" onClick={() => openExpedienteModal(record.id)}>
                    Ver Detalles
                </Button>
            ),
            width: '15%'
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content style={{ padding: '24px' }}>
                <Title level={2} style={{ color: '#1890ff', marginBottom: '24px' }}>
                    Portal Privado
                </Title>

                {loading ? (
                    <p>Cargando estadísticas...</p>
                ) : (
                    <>
                        {/* Fila de 4 tarjetas principales */}
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={6}>
                                <Card
                                    bordered={false}
                                    style={{
                                        background: 'linear-gradient(135deg, #00FF00 0%, #008000 100%)',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                                        color: '#fff',
                                        textAlign: 'center'
                                    }}
                                >
                                    <div style={{ fontWeight: 'bold' }}>Próximas Valoraciones</div>
                                    <div style={{ fontSize: '2rem' }}>
                                        {stats.upcomingValuationsCount}
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} md={6}>
                                <Card
                                    bordered={false}
                                    style={{
                                        background: 'linear-gradient(135deg, #00FF00 0%, #008000 100%)',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                                        color: '#fff',
                                        textAlign: 'center'
                                    }}
                                >
                                    <div style={{ fontWeight: 'bold' }}>Pendientes Entrega</div>
                                    <div style={{ fontSize: '2rem' }}>
                                        {stats.pendingDeliveryCount}
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} md={6}>
                                <Card
                                    bordered={false}
                                    style={{
                                        background: 'linear-gradient(135deg, #00FF00 0%, #008000 100%)',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                                        color: '#fff',
                                        textAlign: 'center'
                                    }}
                                >
                                    <div style={{ fontWeight: 'bold' }}>Control Calidad</div>
                                    <div style={{ fontSize: '2rem' }}>
                                        {stats.pendingQualityCount}
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} md={6}>
                                <Card
                                    bordered={false}
                                    style={{
                                        background: 'linear-gradient(135deg, #00FF00 0%, #008000 100%)',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                                        color: '#fff',
                                        textAlign: 'center'
                                    }}
                                >
                                    <div style={{ fontWeight: 'bold' }}>Pendientes Facturar</div>
                                    <div style={{ fontSize: '2rem' }}>
                                        {stats.pendingBillingCount}
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        {/*
              Fila con "Próximos 5 días..." y "Procedimientos que se valoran HOY"
              Para que se alineen en altura:
              1) align="stretch" en el Row
              2) display: 'flex' y flex: 1 en cada Card
            */}
                        <Row gutter={[16, 16]} style={{ marginTop: '32px' }} align="stretch">
                            <Col xs={24} md={12} style={{ display: 'flex' }}>
                                <Card
                                    title="Próximos 5 días con valoraciones"
                                    bordered={false}
                                    style={{
                                        flex: 1, // se expande para igualar altura
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                                    }}
                                >
                                    {stats.nextValuationDates && stats.nextValuationDates.length > 0 ? (
                                        <List
                                            dataSource={stats.nextValuationDates}
                                            renderItem={(dateStr) => (
                                                <List.Item>
                                                    <Text>{formatSpanishDate(dateStr)}</Text>
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <Text>No hay fechas de valoración en los próximos 5 días.</Text>
                                    )}

                                    <Button
                                        type="primary"
                                        style={{ marginTop: '16px' }}
                                        onClick={() => {
                                            if (onGoCalendario) {
                                                onGoCalendario();
                                            } else {
                                                window.location.href = '/calendario';
                                            }
                                        }}
                                    >
                                        Ir al Calendario
                                    </Button>
                                </Card>
                            </Col>

                            <Col xs={24} md={12} style={{ display: 'flex' }}>
                                <Card
                                    title="Procedimientos que se valoran HOY"
                                    bordered={false}
                                    style={{
                                        flex: 1, // se expande para igualar altura
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                                    }}
                                >
                                    {proceduresToday.length > 0 ? (
                                        <Table
                                            style={{ marginTop: '16px' }}
                                            columns={columnsToday}
                                            dataSource={proceduresToday}
                                            rowKey="id"
                                            pagination={false}
                                        />
                                    ) : (
                                        <Text>Sin señalamientos para el día de hoy. ¡A descansar!</Text>
                                    )}
                                </Card>
                            </Col>
                        </Row>

                        {/* Procedimientos del Próximo Día con valoración */}
                        <div style={{ marginTop: '24px' }}>
                            <Card
                                title="Procedimientos del Próximo Día con Valoración"
                                bordered={false}
                                style={{
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                                }}
                            >
                                {nextClosestDate ? (
                                    <>
                                        <Text strong>
                                            Próximo día con valoración: {formatSpanishDate(nextClosestDate)}
                                        </Text>
                                        <Table
                                            style={{ marginTop: '16px' }}
                                            columns={columnsNextDay}
                                            dataSource={nextDateProcedures}
                                            rowKey="id"
                                            pagination={false}
                                        />
                                    </>
                                ) : (
                                    <Text>No tienes ninguna fecha de valoración futura.</Text>
                                )}
                            </Card>
                        </div>

                        {/* Segunda fila: otras 4 tarjetas */}
                        <div style={{ marginTop: '32px' }}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <Card
                                        bordered={false}
                                        style={{
                                            background: 'linear-gradient(135deg, #36D1DC 0%, #5B86E5 100%)',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                                            color: '#fff',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <div style={{ fontWeight: '500' }}>Valoraciones este mes</div>
                                        <div style={{ fontSize: '1.5rem' }}>
                                            {stats.monthValuationsCount}
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Card
                                        bordered={false}
                                        style={{
                                            background: 'linear-gradient(135deg, #36D1DC 0%, #5B86E5 100%)',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                                            color: '#fff',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <div style={{ fontWeight: '500' }}>Informes entregados mes</div>
                                        <div style={{ fontSize: '1.5rem' }}>
                                            {stats.monthDeliveredCount}
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Card
                                        bordered={false}
                                        style={{
                                            background: 'linear-gradient(135deg, #36D1DC 0%, #5B86E5 100%)',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                                            color: '#fff',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <div style={{ fontWeight: '500' }}>Incomparecencias mes</div>
                                        <div style={{ fontSize: '1.5rem' }}>
                                            {stats.monthIncomparecenciaCount}
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Card
                                        bordered={false}
                                        style={{
                                            background: 'linear-gradient(135deg, #36D1DC 0%, #5B86E5 100%)',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                                            color: '#fff',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <div style={{ fontWeight: '500' }}>Total Informes Entregados</div>
                                        <div style={{ fontSize: '1.5rem' }}>
                                            {stats.totalDeliveredCount}
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </Content>

            {/* Modal ExpedienteDetail */}
            <Modal
                visible={showModalExpediente}
                onCancel={() => setShowModalExpediente(false)}
                footer={null}
                width="80%"
                style={{ top: 20 }}
                destroyOnClose
            >
                {selectedExpedienteId && (
                    <ExpedienteDetail procedimientoId={selectedExpedienteId} />
                )}
            </Modal>
        </Layout>
    );
}

export default PrivatePortalDashboard;
