import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';
import './Plataforma.css';
import Header from '../components/Header';
import NuevoProcedimientoPopup from './NuevoProcedimientoPopup';
import EditarProcedimientoPopup from './EditarProcedimientoPopup';
import EditarNotasPopup from './EditarNotasPopup';
import EditarPeritosPopup from './EditarPeritosPopup';
import NuevoProcedimientoModal from './NuevoProcedimientoModal';
import { Modal, Form, Input, DatePicker, Switch, message } from 'antd';  // <-- import antd
import EditarProcedimientoModal from './EditarProcedimientoModal';
import EditarPeritosModal from './EditarPeritosModal';
import dayjs from 'dayjs';
import ExpedienteDetail from './ExpedienteDetail';


// Mapeo para los nombres amigables de los estados
const estadoMap = {
    solicitado: 'Solicitado',
    asignado_peritos: 'Asignado Peritos',
    informe_entregado: 'Informe Entregado',
    pendiente_descarga: 'Pendiente Descarga',
    informe_descargado: 'Informe Descargado',
    anulado: 'Anulado',
    incomparecencia: 'Incomparecencia',
};

// Constante para formatear la fecha
const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
};

// Constante que devuelve el icono correspondiente a la extensión del archivo
const getIconForExtension = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
        case 'pdf':
            return require('../assets/icons/pdf-icon.svg').default;
        case 'doc':
        case 'docx':
            return require('../assets/icons/doc-icon.svg').default;
        default:
            return require('../assets/icons/default-icon.svg').default;
    }
};


// Componente principal de la plataforma
const Platform = () => {
    const [procedimientos, setProcedimientos] = useState([]);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(''); // Estado para la búsqueda "amortiguada"
    const [selectedProcedimiento, setSelectedProcedimiento] = useState(null);
    const [documentos, setDocumentos] = useState([]);
    const [uploadError, setUploadError] = useState('');
    const [showModal, setShowModal] = useState(false); // Estado para mostrar/ocultar el popup
    const [isNotasPopupVisible, setIsNotasPopupVisible] = useState(false);
    const [isPeritosPopupVisible, setIsPeritosPopupVisible] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false); // Nuevo estado para manejar el filtro
    const [hasInformeEntregado, setHasInformeEntregado] = useState(false);
    const [isIncomparecenciaModalVisible, setIsIncomparecenciaModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [isIncidenciaModalVisible, setIsIncidenciaModalVisible] = useState(false);
    const [formIncidencia] = Form.useForm();
    const { confirm } = Modal;
    const [isFacturacionModalVisible, setIsFacturacionModalVisible] = useState(false);
    const [formFacturacion] = Form.useForm();
    const [isFacturacionPeritoModalVisible, setIsFacturacionPeritoModalVisible] = useState(false);
    const [selectedPeritoIdForFacturacion, setSelectedPeritoIdForFacturacion] = useState(null);
    const [formFacturacionPerito] = Form.useForm();



    // Constante que verifica si el proc seleccionado tiene informe entregado o descargado.
    const isInformeEntregadoODescargado =
        selectedProcedimiento &&
        (selectedProcedimiento.estado === 'informe_entregado' ||
            selectedProcedimiento.estado === 'informe_descargado');


    const getFacturacionIcon = () => {
        if (!selectedProcedimiento) return null;

        return selectedProcedimiento.facturado_administracion
            ? require('../assets/icons/euro_green.svg').default
            : require('../assets/icons/euro_red.svg').default;
    };


    // Efecto para aplicar debounce al searchTerm. Espera 500ms tras el último cambio
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        const checkInformeEntregado = async () => {
            try {
                const response = await axiosInstance.get('procedimientos/hay_informe_entregado/');
                setHasInformeEntregado(response.data.hay_informe_entregado);
            } catch (error) {
                console.error('Error checking informe entregado:', error);
                setHasInformeEntregado(false);
            }
        };

        checkInformeEntregado();
    }, []);


    useEffect(() => {
        const fetchProcedimientos = async () => {
            try {
                let response;

                if (isFilterActive) {
                    // Petición a la nueva endpoint que trae TODOS los "informe_entregado".
                    response = await axiosInstance.get('procedimientos/listar_informes_entregados/', {
                        params: {
                            search: debouncedSearchTerm
                        }
                    });
                } else {
                    // Petición original, limitando a 25.
                    response = await axiosInstance.get('procedimientos/listar_procedimientos/', {
                        params: {
                            search: debouncedSearchTerm,
                            limit: 25
                        }
                    });
                }

                const data = response.data;

                if (!Array.isArray(data)) {
                    setError('La respuesta no es un array, revisa la ruta en el back.');
                    setProcedimientos([]);
                    return;
                }

                if (data.length === 0) {
                    setError('No hay procedimientos judiciales disponibles o coincidentes.');
                    setProcedimientos([]);
                } else {
                    setError('');
                    // Ordenar por fecha desc
                    const sortedProcedimientos = data.sort(
                        (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
                    );
                    setProcedimientos(sortedProcedimientos);
                }

            } catch (error) {
                setError('Error al cargar los procedimientos');
                setProcedimientos([]);
                console.error(error);
            }
        };

        fetchProcedimientos();
    }, [debouncedSearchTerm, isFilterActive]);



    useEffect(() => {
        const selectedId = localStorage.getItem('selectedProcedimiento');
        if (selectedId) {
            const selected = procedimientos.find(p => p.id === parseInt(selectedId, 10));
            if (selected) {
                setSelectedProcedimiento(selected);
            }
            localStorage.removeItem('selectedProcedimiento');
        } else if (procedimientos.length > 0) {
            setSelectedProcedimiento(procedimientos[0]);
        }
    }, [procedimientos]);

    useEffect(() => {
        if (selectedProcedimiento) {
            const fetchDocumentos = async () => {
                try {
                    const response = await axiosInstance.get(`procedimientos/${selectedProcedimiento.id}/documentos/`);
                    setDocumentos(response.data);
                } catch (error) {
                    console.error('Error al cargar los documentos:', error);
                }
            };

            fetchDocumentos();
        }
    }, [selectedProcedimiento]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFilterClick = () => {
        setIsFilterActive(prevState => !prevState);
    };

    const handleSelectProcedimiento = (procedimiento) => {
        setSelectedProcedimiento(procedimiento);
        setUploadError('');
    };

    const handleFileUpload = async (event, esInforme = false, esCitacion = false, esJustificante = false) => {
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append('documento', files[i]);
            formData.append('es_informe', esInforme.toString());
            formData.append('es_citacion', esCitacion.toString());
            formData.append('es_justificante', esJustificante.toString());

            try {
                await axiosInstance.post(`procedimientos/${selectedProcedimiento.id}/documentos/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } catch (error) {
                setUploadError('Error al subir los archivos');
                console.error('Error al subir los archivos:', error);
                return;
            }
        }

        try {
            const response = await axiosInstance.get(`procedimientos/${selectedProcedimiento.id}/documentos/`);
            setDocumentos(response.data);
            setUploadError('');
        } catch (error) {
            setUploadError('Error al actualizar la lista de documentos');
            console.error('Error al actualizar la lista de documentos:', error);
        }

        try {
            const response = await axiosInstance.get(`procedimientos/${selectedProcedimiento.id}/`);
            setSelectedProcedimiento(response.data);
        } catch (error) {
            console.error('Error al actualizar el estado del procedimiento:', error);
        }
    };

    const handleDrop = (event, esInforme = false, esCitacion = false, esJustificante = false) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFileUpload({ target: { files } }, esInforme, esCitacion, esJustificante);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleAddClick = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleSubmitNuevoProcedimiento = (nuevoProcedimiento) => {
        setProcedimientos([nuevoProcedimiento, ...procedimientos]);
        setShowModal(false);
    };

    const handleDownloadAll = async () => {
        try {
            const response = await axiosInstance.get(`/procedimientos/${selectedProcedimiento.id}/descargar_todos_los_documentos/`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `documentos_procedimiento_${selectedProcedimiento.id}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error al descargar los documentos:', error);
        }
    };

    const handleDownloadAllInformes = async () => {
        try {
            const response = await axiosInstance.get(`/procedimientos/${selectedProcedimiento.id}/descargar_informes/`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `informes_procedimiento_${selectedProcedimiento.id}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            await axiosInstance.post(`/procedimientos/${selectedProcedimiento.id}/marcar_informe_descargado/`);
        } catch (error) {
            console.error('Error al descargar los informes o actualizar el estado:', error);
        }
    };

    const handleConfirmIncomparecencia = async (values) => {
        // values tendrá { comentario_nueva_cita: '...' }
        if (!selectedProcedimiento) {
            message.error('No se ha seleccionado procedimiento.');
            return;
        }

        try {
            await axiosInstance.post(
                `/procedimientos/${selectedProcedimiento.id}/notificar_incomparecencia/`,
                { comentario_nueva_cita: values.comentario_nueva_cita }
            );

            // Refrescar el procedimiento para ver el cambio de estado en la interfaz
            const updated = await axiosInstance.get(`/procedimientos/${selectedProcedimiento.id}/`);
            setSelectedProcedimiento(updated.data);

            message.success('¡Incomparecencia notificada correctamente!');
            setIsIncomparecenciaModalVisible(false);
            form.resetFields();  // Limpia el form
        } catch (error) {
            console.error('Error notificando incomparecencia:', error);
            message.error('Error al notificar la incomparecencia.');
        }
    };


    const handleConfirmIncidencia = async (values) => {
        // values => { motivo_incidencia: '...' }
        if (!selectedProcedimiento) {
            message.error('No se ha seleccionado ningún procedimiento.');
            return;
        }

        try {
            await axiosInstance.post(
                `/procedimientos/${selectedProcedimiento.id}/notificar_incidencia/`,
                { motivo_incidencia: values.motivo_incidencia }
            );
            // Opcional: recargar procedimiento
            const updated = await axiosInstance.get(`/procedimientos/${selectedProcedimiento.id}/`);
            setSelectedProcedimiento(updated.data);

            message.success('Incidencia notificada correctamente.');
            setIsIncidenciaModalVisible(false);
            formIncidencia.resetFields();
        } catch (error) {
            console.error('Error al notificar incidencia:', error);
            message.error('Error al notificar la incidencia.');
        }
    };


    const handleClickAnular = () => {
        if (!selectedProcedimiento) {
            message.error('No hay procedimiento seleccionado.');
            return;
        }

        confirm({
            title: '¿Deseas anular este procedimiento?',
            content: 'Esta acción cambiará el estado a "Anulado".',
            okText: 'Sí, anular',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                try {
                    await axiosInstance.post(`/procedimientos/${selectedProcedimiento.id}/anular_procedimiento/`);

                    // Opcional: refrescar el procedimiento
                    const updated = await axiosInstance.get(`/procedimientos/${selectedProcedimiento.id}/`);
                    setSelectedProcedimiento(updated.data);

                    message.success('Procedimiento anulado correctamente.');
                } catch (error) {
                    console.error('Error al anular el procedimiento:', error);
                    message.error('No se pudo anular el procedimiento.');
                }
            }
        });
    };


    const handleFacturacionClick = () => {
        if (!selectedProcedimiento) return;
        // Inicializar el formulario con los valores actuales
        formFacturacion.setFieldsValue({
            facturado_administracion: selectedProcedimiento.facturado_administracion,
            // Si hay fecha en el backend, la convertimos a un objeto moment/dayjs
            fecha_factura_administracion: selectedProcedimiento.fecha_factura_administracion
                ? dayjs(selectedProcedimiento.fecha_factura_administracion)
                : null
        });

        setIsFacturacionModalVisible(true);
    };


    const handleFacturacionSubmit = async (values) => {
        if (!selectedProcedimiento) return;
        // values: { facturado_administracion: bool, fecha_factura_administracion: Dayjs }

        try {
            // Convertir la fecha a ISO si existe
            let fechaISO = null;
            if (values.fecha_factura_administracion) {
                fechaISO = values.fecha_factura_administracion.toISOString();
            }

            const patchData = {
                facturado_administracion: values.facturado_administracion,
                fecha_factura_administracion: fechaISO
            };

            // Llamada PATCH
            await axiosInstance.patch(
                `procedimientos/${selectedProcedimiento.id}/`,
                patchData
            );

            // Refrescar el objeto
            const resp = await axiosInstance.get(`procedimientos/${selectedProcedimiento.id}/`);
            setSelectedProcedimiento(resp.data);

            message.success('Facturación actualizada correctamente.');
            setIsFacturacionModalVisible(false);
        } catch (error) {
            console.error('Error al actualizar facturación:', error);
            message.error('No se pudo actualizar la facturación.');
        }
    };

    const [facturasPerito, setFacturasPerito] = useState([]);
    // Podría ser un array, o un diccionario (depende de cómo quieras consultarlo).
    useEffect(() => {
        if (!selectedProcedimiento) return;
        // Carga la facturación de TODOS los peritos para ese procedimiento
        const fetchFacturasPerito = async () => {
            try {
                const resp = await axiosInstance.get(
                    `/facturas-perito/?procedimiento=${selectedProcedimiento.id}`
                );
                setFacturasPerito(resp.data);
                // resp.data será un array de objetos {id, procedimiento, perito, facturado, fecha_factura}
            } catch (error) {
                console.error('Error al cargar facturas de perito:', error);
            }
        };

        fetchFacturasPerito();
    }, [selectedProcedimiento]);


    const openFacturacionPeritoModal = (peritoId) => {
        setSelectedPeritoIdForFacturacion(peritoId);

        // Buscamos si hay un registro
        const registroExistente = facturasPerito.find(
            (f) => f.perito === peritoId
        );

        if (registroExistente) {
            // Ajustamos los campos del form
            formFacturacionPerito.setFieldsValue({
                facturado: registroExistente.facturado || false,
                fecha_factura: registroExistente.fecha_factura
                    ? dayjs(registroExistente.fecha_factura)
                    : null,
            });
        } else {
            // Si no existe, ponemos valores por defecto
            formFacturacionPerito.setFieldsValue({
                facturado: false,
                fecha_factura: null,
            });
        }

        setIsFacturacionPeritoModalVisible(true);
    };

    const closeFacturacionPeritoModal = () => {
        setIsFacturacionPeritoModalVisible(false);
        setSelectedPeritoIdForFacturacion(null);
        formFacturacionPerito.resetFields();
    };





    // COMPONENTE EDITAR PROCEDIMIENTO
    const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
    const handleEditClick = () => {
        setIsEditPopupVisible(true);
    };

    const handleNotasClick = () => {
        setIsNotasPopupVisible(true);
    };

    const handleEditPeritosClick = () => {
        setIsPeritosPopupVisible(true);
    };

    // ROLES DE USUARIO
    const [userRole, setUserRole, ] = useState(null);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        axiosInstance.get('/mi_usuario/')
            .then(response => {
                console.log('Rol del usuario:', response.data.role);
                setUserRole(response.data.role.trim());
                setUserId(response.data.id);
            })
            .catch(error => {
                console.error('Error al obtener el rol del usuario:', error);
            });
    }, []);

    const hasInformeSelected = documentos.some(doc => doc.es_informe);

    // FIN ROLES DE USUARIO

    return (
        <div className="plataforma-container">
            <Header />
            <div className="plataforma-content">
                <div className="sidebar">
                    <div className="search-filters">
                        <input
                            type="text"
                            className="search-bar"
                            placeholder="Buscar por juzgado, N.º Proc. o ID"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <div className="search-buttons">
                            <img src={require('../assets/icons/filter.svg').default} alt="Filter" className="filter-icon" />
                            {userRole !== 'Perito' && (
                                <img src={require('../assets/icons/add.svg').default} alt="Add" className="add-icon"
                                     onClick={handleAddClick} />)}

                            {userRole !== 'Perito' && hasInformeEntregado && (
                                <img src={require('../assets/icons/download.svg').default} alt="Descargar Informes"
                                     className="filter-icon" onClick={handleFilterClick} />)}

                        </div>
                    </div>
                    <div className="procedimientos-list">
                        {error && <p>{error}</p>}
                        <ul>
                            {procedimientos.map(procedimiento => (
                                <li
                                    key={procedimiento.id}
                                    onClick={() => handleSelectProcedimiento(procedimiento)}
                                    className={procedimiento.id === selectedProcedimiento?.id ? 'selected' : ''}
                                >
                                    <div className="procedimiento-caja">
                                        <span className="estado-procedimiento">
                                            {estadoMap[procedimiento.estado]}
                                        </span>
                                        <div className="procedimiento-info">
                                            <p><strong>{procedimiento.juzgado_nombre || 'N/A'}</strong></p>
                                            <p>Procedimiento: {procedimiento.numero_procedimiento}</p>
                                            <p>Especialidad: {procedimiento.especialidad_nombre || 'N/A'}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="main-content">
                    {selectedProcedimiento ? (
                        <ExpedienteDetail procedimientoId={selectedProcedimiento.id} />
                    ) : (
                        <p>Selecciona un procedimiento para ver los detalles.</p>
                    )}
                </div>
            </div>
            {showModal && (
                <NuevoProcedimientoModal
                    visible={showModal}
                    onClose={handleModalClose}
                    onSubmit={(nuevoProcedimiento) => {
                        setProcedimientos([nuevoProcedimiento, ...procedimientos]);
                        setShowModal(false);
                    }}
                />
            )}
            {isEditPopupVisible && (
                <EditarProcedimientoModal
                    visible={isEditPopupVisible}
                    procedimiento={selectedProcedimiento}
                    onClose={() => setIsEditPopupVisible(false)}
                    onUpdate={(updatedProc) => {
                        setSelectedProcedimiento(updatedProc);
                        setIsEditPopupVisible(false);
                    }}
                />
            )}

            {isNotasPopupVisible && (
                <EditarNotasPopup
                    onClose={() => setIsNotasPopupVisible(false)}
                    procedimiento={selectedProcedimiento}
                    onUpdate={(updatedProcedimiento) => {
                        setSelectedProcedimiento(updatedProcedimiento);
                        setIsNotasPopupVisible(false);
                    }}
                />
            )}

            {isPeritosPopupVisible && (
                <EditarPeritosModal
                    visible={isPeritosPopupVisible}
                    onClose={() => setIsPeritosPopupVisible(false)}
                    procedimiento={selectedProcedimiento}
                    onUpdate={(updatedProcedimiento) => {
                        setSelectedProcedimiento(updatedProcedimiento);
                        setIsPeritosPopupVisible(false);
                    }}
                />
            )}


            <Modal
                title="Confirmar Incomparecencia"
                visible={isIncomparecenciaModalVisible}
                onCancel={() => setIsIncomparecenciaModalVisible(false)}
                onOk={() => form.submit()}  // Al hacer click en OK, se envía el formulario
                okText="Confirmar"
                cancelText="Cancelar"
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleConfirmIncomparecencia} // cuando el form se valide y envíe
                >
                    <Form.Item
                        label="Información sobre nueva cita (obligatorio)"
                        name="comentario_nueva_cita"
                        rules={[
                            { required: true, message: 'Por favor, indique los detalles de la nueva cita o un comentario.' }
                        ]}
                    >
                        <Input.TextArea rows={4} placeholder="Indique si hay nueva cita, fecha, etc." />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Notificar Incidencia"
                visible={isIncidenciaModalVisible}
                onCancel={() => setIsIncidenciaModalVisible(false)}
                onOk={() => formIncidencia.submit()} // Se envía el formulario
                okText="Confirmar"
                cancelText="Cancelar"
            >
                <Form
                    form={formIncidencia}
                    layout="vertical"
                    onFinish={handleConfirmIncidencia} // Al hacer submit
                >
                    <Form.Item
                        label="Motivo de la Incidencia"
                        name="motivo_incidencia"
                        rules={[{ required: true, message: 'Por favor, describa la incidencia.' }]}
                    >
                        <Input.TextArea rows={4} placeholder="Describa la incidencia..." />
                    </Form.Item>
                </Form>
            </Modal>


            {/* Modal de Facturación a la Administración */}
            <Modal
                title="Facturación a la Administración"
                visible={isFacturacionModalVisible}
                onCancel={() => setIsFacturacionModalVisible(false)}
                onOk={() => formFacturacion.submit()}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form
                    form={formFacturacion}
                    layout="vertical"
                    onFinish={handleFacturacionSubmit}
                >
                    <Form.Item
                        label="¿Facturado?"
                        name="facturado_administracion"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        label="Fecha de Factura"
                        name="fecha_factura_administracion"
                    >
                        <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form>
            </Modal>


            {/* Modal de Facturación del Perito */}
            <Modal
                title="Facturación del Perito"
                visible={isFacturacionPeritoModalVisible}
                onCancel={closeFacturacionPeritoModal}
                onOk={() => formFacturacionPerito.submit()}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form
                    form={formFacturacionPerito}
                    layout="vertical"
                    onFinish={async (values) => {
                        try {
                            // Comprobamos si existe ya la factura
                            const registroExistente = facturasPerito.find(
                                (f) => f.perito === selectedPeritoIdForFacturacion
                            );

                            // Convertimos la fecha a ISO
                            let fechaISO = null;
                            if (values.fecha_factura) {
                                fechaISO = values.fecha_factura.toISOString();
                            }

                            if (registroExistente) {
                                // Hacemos PATCH
                                await axiosInstance.patch(
                                    `/facturas-perito/${registroExistente.id}/`,
                                    {
                                        facturado: values.facturado,
                                        fecha_factura: fechaISO,
                                    }
                                );

                            } else {
                                // No existe: debemos CREARLO con un POST
                                await axiosInstance.post(
                                    '/facturas-perito/',
                                    {
                                        procedimiento: selectedProcedimiento.id,
                                        perito: selectedPeritoIdForFacturacion,
                                        facturado: values.facturado,
                                        fecha_factura: fechaISO,
                                    }
                                );
                            }

                            // Refrescamos la lista "facturasPerito"
                            const resp = await axiosInstance.get(
                                `/facturas-perito/?procedimiento=${selectedProcedimiento.id}`
                            );
                            setFacturasPerito(resp.data);

                            message.success('Facturación guardada correctamente');
                            closeFacturacionPeritoModal();

                        } catch (error) {
                            console.error('Error guardando facturación perito:', error);
                            message.error('No se pudo guardar la facturación.');
                        }
                    }}
                >
                    <Form.Item
                        label="¿Facturado?"
                        name="facturado"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        label="Fecha de Factura"
                        name="fecha_factura"
                    >
                        <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form>
            </Modal>



        </div>
    );

};

export default Platform;
