// ExpedienteDetail.js
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, DatePicker, Switch, message } from 'antd';
import dayjs from 'dayjs';

import axiosInstance from '../services/axiosConfig';
import './Plataforma.css';

// Subcomponentes que usas en Platform:
import EditarProcedimientoModal from './EditarProcedimientoModal';
import EditarNotasPopup from './EditarNotasPopup';
import EditarPeritosModal from './EditarPeritosModal';
import EditarValoracionesModal from './EditarValoracionesModal';
import MensajesProcedimientoModal from './MensajesProcedimientoModal';


//Manejo de PDFs
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();




// Mapeo de estados
const estadoMap = {
    solicitado: 'Solicitado',
    asignado_peritos: 'Asignado Peritos',
    informe_entregado: 'Informe Entregado',
    pendiente_descarga: 'Pendiente Descarga',
    informe_descargado: 'Informe Descargado',
    anulado: 'Anulado',
    incomparecencia: 'Incomparecencia',
};

// Helper para formatear fecha
const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
};

// Icono según extensión
const getIconForExtension = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
        case 'pdf':
            return require('../assets/icons/pdf-icon.svg').default;
        case 'doc':
        case 'docx':
            return require('../assets/icons/doc-icon.svg').default;
        default:
            return require('../assets/icons/default-icon.svg').default;
    }
};

// Componente principal de detalle de expediente
function ExpedienteDetail({ procedimientoId }) {
    // Datos del procedimiento
    const [procedimiento, setProcedimiento] = useState(null);
    const [documentos, setDocumentos] = useState([]);
    const [uploadError, setUploadError] = useState('');

    // Estados de modales
    const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
    const [isNotasPopupVisible, setIsNotasPopupVisible] = useState(false);
    const [isPeritosPopupVisible, setIsPeritosPopupVisible] = useState(false);

    const [isIncomparecenciaModalVisible, setIsIncomparecenciaModalVisible] = useState(false);
    const [isIncidenciaModalVisible, setIsIncidenciaModalVisible] = useState(false);
    const [isFacturacionModalVisible, setIsFacturacionModalVisible] = useState(false);
    const [isFacturacionPeritoModalVisible, setIsFacturacionPeritoModalVisible] = useState(false);

    // Form instances
    const [formIncomparecencia] = Form.useForm();
    const [formIncidencia] = Form.useForm();
    const [formFacturacion] = Form.useForm();
    const [formFacturacionPerito] = Form.useForm();

    // Facturación Perito
    const [facturasPerito, setFacturasPerito] = useState([]);
    const [selectedPeritoIdForFacturacion, setSelectedPeritoIdForFacturacion] = useState(null);

    // Roles
    const [userRole, setUserRole] = useState(null);
    const [userId, setUserId] = useState(null);

    // Confirm
    const { confirm } = Modal;

    const [isValoracionesModalVisible, setIsValoracionesModalVisible] = useState(false);

    const [peritacionExcepcional, setPeritacionExcepcional] = useState(null);

    // Manejar las descargas de informes para el rol Juzgado
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [previewPdfUrl, setPreviewPdfUrl] = useState(null);

    // Para el popup del chat
    const [isChatModalVisible, setIsChatModalVisible] = useState(false);


    // Efecto: Cargar rol de usuario
    useEffect(() => {
        axiosInstance.get('/mi_usuario/')
            .then((resp) => {
                setUserRole(resp.data.role.trim());
                setUserId(resp.data.id);
            })
            .catch((error) => {
                console.error('Error al obtener rol del usuario:', error);
            });
    }, []);

    // Efecto: Cargar datos del procedimiento y documentos
    useEffect(() => {
        if (!procedimientoId) return;

        const fetchDatos = async () => {
            try {
                // 1) Procedimiento
                const respProc = await axiosInstance.get(`procedimientos/${procedimientoId}/`);
                setProcedimiento(respProc.data);

                // 2) Documentos
                const respDocs = await axiosInstance.get(`procedimientos/${procedimientoId}/documentos/`);
                setDocumentos(respDocs.data);

                // 3) Facturas de Perito
                const respFact = await axiosInstance.get(`/facturas-perito/?procedimiento=${procedimientoId}`);
                setFacturasPerito(respFact.data);

                const respExc = await axiosInstance.get(
                    `/peritaciones-excepcionales/?procedimiento=${procedimientoId}`
                );
                if (respExc.data && respExc.data.length > 0) {
                    setPeritacionExcepcional(respExc.data[0]);
                } else {
                    setPeritacionExcepcional(null);
                }

            } catch (error) {
                console.error('Error al cargar procedimiento:', error);
            }
        };

        fetchDatos();
    }, [procedimientoId]);

    // Helpers
    const isInformeEntregadoODescargado = procedimiento
        && (procedimiento.estado === 'informe_entregado'
            || procedimiento.estado === 'informe_descargado');

    const hasInformeSelected = documentos.some((doc) => doc.es_informe);

    // Icono facturación a la administración
    const getFacturacionIcon = () => {
        if (!procedimiento) return null;
        return procedimiento.facturado_administracion
            ? require('../assets/icons/euro_green.svg').default
            : require('../assets/icons/euro_red.svg').default;
    };

    // --- Lógica de subidas/descargas idéntica a Platform ---
    const handleFileUpload = async (event, esInforme = false, esCitacion = false, esJustificante = false) => {
        if (!procedimiento) return;
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append('documento', files[i]);
            formData.append('es_informe', esInforme.toString());
            formData.append('es_citacion', esCitacion.toString());
            formData.append('es_justificante', esJustificante.toString());

            try {
                await axiosInstance.post(`procedimientos/${procedimiento.id}/documentos/`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            } catch (error) {
                setUploadError('Error al subir los archivos');
                console.error(error);
                return;
            }
        }

        try {
            // Refrescar documentos
            const respDocs = await axiosInstance.get(`procedimientos/${procedimiento.id}/documentos/`);
            setDocumentos(respDocs.data);
            setUploadError('');
        } catch (error) {
            setUploadError('Error al actualizar la lista de documentos');
            console.error(error);
        }

        try {
            // Refrescar procedimiento
            const respProc = await axiosInstance.get(`procedimientos/${procedimiento.id}/`);
            setProcedimiento(respProc.data);
        } catch (error) {
            console.error(error);
        }
    };

    const reloadProcedimiento = async () => {
        if (!procedimiento) return;
        try {
            const updated = await axiosInstance.get(`procedimientos/${procedimiento.id}/`);
            setProcedimiento(updated.data);
        } catch (err) {
            console.error('Error recargando procedimiento:', err);
        }
    };


    const handleDrop = (event, esInforme = false, esCitacion = false, esJustificante = false) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFileUpload({ target: { files } }, esInforme, esCitacion, esJustificante);
    };
    const handleDragOver = (event) => event.preventDefault();

    const handleDownloadAll = async () => {
        if (!procedimiento) return;
        try {
            const response = await axiosInstance.get(
                `/procedimientos/${procedimiento.id}/descargar_todos_los_documentos/`,
                { responseType: 'blob' },
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `documentos_procedimiento_${procedimiento.id}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error al descargar los documentos:', error);
        }
    };

    const handleDownloadAllInformes = async () => {
        if (!procedimiento) return;
        try {
            const response = await axiosInstance.get(
                `/procedimientos/${procedimiento.id}/descargar_informes/`,
                { responseType: 'blob' },
            );
            // si no da error, generamos el blob y descargamos
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `informes_procedimiento_${procedimiento.id}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            // OPCIONAL: Marcar informe descargado
            await axiosInstance.post(
                `/procedimientos/${procedimiento.id}/marcar_informe_descargado/`
            );
            // refrescar
            const updated = await axiosInstance.get(`procedimientos/${procedimiento.id}/`);
            setProcedimiento(updated.data);

        } catch (error) {
            if (error.response && error.response.status === 403) {
                // Mensaje personalizado
                message.error(error.response.data.error ||
                    'Debes subir primero el justificante firmado antes de descargar el informe.');
            } else {
                console.error(error);
                message.error('Error al descargar los informes.');
            }
        }
    };

    //Maneja el sistema de descargas y justificantes del rol Juzgado
    const handleDownloadInforme = async () => {
        if (!procedimiento) return;

        try {
            const response = await axiosInstance.get(
                `/procedimientos/${procedimiento.id}/descargar_informes/`,
                { responseType: 'blob' },
            );
            // Si el back no da error (200 OK), generamos la descarga
            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `informes_procedimiento_${procedimiento.id}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Marcar informe descargado y refrescar
            await axiosInstance.post(`/procedimientos/${procedimiento.id}/marcar_informe_descargado/`);
            const updated = await axiosInstance.get(`procedimientos/${procedimiento.id}/`);
            setProcedimiento(updated.data);

        } catch (error) {
            if (error.response && error.response.status === 403) {
                // Significa que el back ha bloqueado la descarga porque falta justificante subido
                message.warning(
                    error.response.data.error
                    || 'Debes subir primero el justificante firmado antes de descargar el informe.'
                );
                // Activamos el modal
                setIsPreviewModalVisible(true);

                // Buscamos el primer documento que sea es_informe para mostrar una "vista previa"
                // (asumiendo que guardaste la URL en doc.archivo).
                // Deberás descargar el PDF y guardarlo en un Blob, para pasárselo a react-pdf.
                // OJO: si hay varios informes, escoge uno o haz tu propia lógica.

                const informeDoc = documentos.find((d) => d.es_informe);
                if (informeDoc) {
                    // Descargamos el PDF para mostrarlo
                    try {
                        const pdfResp = await axiosInstance.get(`/proxy_pdf_preview/${informeDoc.id}/`, {
                            responseType: 'blob'
                        });
                        const pdfBlobUrl = window.URL.createObjectURL(new Blob([pdfResp.data]));
                        setPreviewPdfUrl(pdfBlobUrl);
                    } catch (pdfError) {
                        console.error('Error al descargar el PDF para preview:', pdfError);
                        setPreviewPdfUrl(null);
                    }
                } else {
                    setPreviewPdfUrl(null);
                }
            } else {
                console.error(error);
                message.error('Error al descargar los informes.');
            }
        }
    };


    // --- Incomparecencia ---
    const handleConfirmIncomparecencia = async (values) => {
        if (!procedimiento) {
            message.error('No se ha seleccionado procedimiento.');
            return;
        }
        try {
            await axiosInstance.post(
                `/procedimientos/${procedimiento.id}/notificar_incomparecencia/`,
                { comentario_nueva_cita: values.comentario_nueva_cita }
            );
            const updated = await axiosInstance.get(`procedimientos/${procedimiento.id}/`);
            setProcedimiento(updated.data);

            message.success('¡Incomparecencia notificada correctamente!');
            setIsIncomparecenciaModalVisible(false);
            formIncomparecencia.resetFields();
        } catch (error) {
            console.error('Error notificando incomparecencia:', error);
            message.error('Error al notificar la incomparecencia.');
        }
    };

    // --- Incidencia ---
    const handleConfirmIncidencia = async (values) => {
        if (!procedimiento) {
            message.error('No se ha seleccionado ningún procedimiento.');
            return;
        }
        try {
            await axiosInstance.post(
                `/procedimientos/${procedimiento.id}/notificar_incidencia/`,
                { motivo_incidencia: values.motivo_incidencia }
            );
            const updated = await axiosInstance.get(`procedimientos/${procedimiento.id}/`);
            setProcedimiento(updated.data);

            message.success('Incidencia notificada correctamente.');
            setIsIncidenciaModalVisible(false);
            formIncidencia.resetFields();
        } catch (error) {
            console.error('Error al notificar incidencia:', error);
            message.error('Error al notificar la incidencia.');
        }
    };

    // --- Anular procedimiento ---
    const handleClickAnular = () => {
        if (!procedimiento) {
            message.error('No hay procedimiento seleccionado.');
            return;
        }
        confirm({
            title: '¿Deseas anular este procedimiento?',
            content: 'Esta acción cambiará el estado a "Anulado".',
            okText: 'Sí, anular',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                try {
                    await axiosInstance.post(`/procedimientos/${procedimiento.id}/anular_procedimiento/`);
                    const updated = await axiosInstance.get(`procedimientos/${procedimiento.id}/`);
                    setProcedimiento(updated.data);

                    message.success('Procedimiento anulado correctamente.');
                } catch (error) {
                    console.error('Error al anular el procedimiento:', error);
                    message.error('No se pudo anular el procedimiento.');
                }
            },
        });
    };

    // --- Facturación a la Administración ---
    const handleFacturacionClick = () => {
        if (!procedimiento) return;
        formFacturacion.setFieldsValue({
            facturado_administracion: procedimiento.facturado_administracion,
            fecha_factura_administracion: procedimiento.fecha_factura_administracion
                ? dayjs(procedimiento.fecha_factura_administracion)
                : null,
        });
        setIsFacturacionModalVisible(true);
    };

    const handleFacturacionSubmit = async (values) => {
        if (!procedimiento) return;
        try {
            let fechaISO = null;
            if (values.fecha_factura_administracion) {
                fechaISO = values.fecha_factura_administracion.toISOString();
            }

            await axiosInstance.patch(
                `procedimientos/${procedimiento.id}/`,
                {
                    facturado_administracion: values.facturado_administracion,
                    fecha_factura_administracion: fechaISO,
                }
            );

            const resp = await axiosInstance.get(`procedimientos/${procedimiento.id}/`);
            setProcedimiento(resp.data);

            message.success('Facturación actualizada correctamente.');
            setIsFacturacionModalVisible(false);
        } catch (error) {
            console.error('Error al actualizar facturación:', error);
            message.error('No se pudo actualizar la facturación.');
        }
    };

    // --- Facturación Perito ---
    const openFacturacionPeritoModal = (peritoId) => {
        setSelectedPeritoIdForFacturacion(peritoId);
        const registroExistente = facturasPerito.find((f) => f.perito?.id === peritoId);
        if (registroExistente) {
            formFacturacionPerito.setFieldsValue({
                facturado: registroExistente.facturado || false,
                fecha_factura: registroExistente.fecha_factura
                    ? dayjs(registroExistente.fecha_factura)
                    : null,
            });
        } else {
            formFacturacionPerito.setFieldsValue({
                facturado: false,
                fecha_factura: null,
            });
        }
        setIsFacturacionPeritoModalVisible(true);
    };

    const renderDescargaJustificante = () => {
        // Encuentra el doc con es_justificante = true,
        // que no sea del user actual (asumiendo que es la plantilla).
        const docJustificante = documentos.find(
            (doc) => doc.es_justificante && doc.uploaded_by !== userId
        );

        if (!docJustificante) {
            return <p>No hay justificante disponible para descargar.</p>;
        }

        return (
            <button
                className="ant-btn ant-btn-default"
                onClick={() => {
                    window.open(docJustificante.archivo, '_blank'); // abre en nueva pestaña
                }}
            >
                Descargar Justificante (sin firmar)
            </button>
        );
    };


    const handleUploadJustificante = async (event) => {
        const files = event.target.files;
        if (!files || files.length === 0) return;
        await uploadJustificante(files[0]);
    };

    const handleDropJustificante = async (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (!files || files.length === 0) return;
        await uploadJustificante(files[0]);
    };

// Reutilizamos la misma lógica de "POST /documentos/", con "es_justificante"
    const uploadJustificante = async (file) => {
        if (!procedimiento) return;
        const formData = new FormData();
        formData.append('documento', file);
        formData.append('es_informe', 'false');
        formData.append('es_citacion', 'false');
        formData.append('es_justificante', 'true');

        try {
            await axiosInstance.post(
                `procedimientos/${procedimiento.id}/documentos/`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            message.success('Justificante firmado subido con éxito. Ya puedes descargar el informe.');

            // Refrescar la lista de documentos
            const respDocs = await axiosInstance.get(`procedimientos/${procedimiento.id}/documentos/`);
            setDocumentos(respDocs.data);

        } catch (error) {
            console.error('Error al subir justificante firmado:', error);
            message.error('Error al subir el justificante firmado.');
        }
    };


    const closeFacturacionPeritoModal = () => {
        setIsFacturacionPeritoModalVisible(false);
        setSelectedPeritoIdForFacturacion(null);
        formFacturacionPerito.resetFields();
    };

    // --- Editar Procedimiento, Notas, Peritos ---
    const handleEditClick = () => setIsEditPopupVisible(true);
    const handleNotasClick = () => setIsNotasPopupVisible(true);
    const handleEditPeritosClick = () => setIsPeritosPopupVisible(true);



    if (!procedimiento) {
        return <p>Cargando datos del procedimiento...</p>;
    }

    // ----------- ESTRUCTURA EXACTA DE PLATFORM (sin sidebar/cabecera) -----------
    return (
        <div className="detalle-container">
            {/* Bloque principal */}
            <div className="detalle-procedimiento">
                {/* Icono de facturación - solo Admin */}
                {userRole === 'Admin' && (
                    <img
                        src={getFacturacionIcon()}
                        alt="Facturación administración"
                        style={{
                            width: '28px',
                            height: '28px',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '10px',
                            right: '115px',
                        }}
                        onClick={handleFacturacionClick}
                    />
                )}
                {/* Icono editar procedimiento, si no es Perito */}
                {userRole !== 'Perito' && (
                    <img
                        src={require('../assets/icons/edit.svg').default}
                        alt="Editar procedimiento"
                        className="edit-icon-top-right"
                        onClick={handleEditClick}
                    />
                )}
                {/* Icono para editar mensajes del procedimiento */}
                <img
                    src={require('../assets/icons/chat.svg').default}
                    alt="Mensajes"
                    style={{
                        width: '28px',
                        height: '28px',
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '10px',
                        right: '45px',
                    }}
                    onClick={() => setIsChatModalVisible(true)}
                />

                {/* Icono para editar notas */}
                {userRole !== 'Perito' && (
                    <img
                        src={require('../assets/icons/notas.svg').default}
                        alt="Editar notas"
                        style={{
                            width: '28px',
                            height: '28px',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '10px',
                            right: '80px',
                        }}
                        onClick={() => setIsNotasPopupVisible(true)}
                    />
                )}

                <h2>Detalles del Procedimiento</h2>
                {peritacionExcepcional && (
                    <div
                        style={{
                            backgroundColor: 'orange',
                            color: '#fff',
                            padding: '6px 12px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            marginBottom: '8px',
                            textAlign: 'center'
                        }}
                    >
                        PERITACIÓN EXCEPCIONAL
                    </div>
                )}

                <p className="juzgado">{procedimiento.juzgado_nombre || 'N/A'}</p>
                <p className="numero-procedimiento">{procedimiento.numero_procedimiento || 'N/A'}</p>
                <p className="detalle-estado-procedimiento">
                    {estadoMap[procedimiento.estado] || 'N/A'} - {procedimiento.especialidad_nombre || 'N/A'} (ID {procedimiento.id})
                </p>

                <p className="detalle-estado-fechas">Fechas Relevantes:</p>
                <div className="fechas-procedimiento">
                    <div className="fecha-item">
                        <p className="fecha-titulo">Solicitado</p>
                        <p>{procedimiento.fecha_creacion ? formatDate(procedimiento.fecha_creacion) : 'No disponible'}</p>
                    </div>
                    <div className="fecha-item">
                        <p className="fecha-titulo">Asignado</p>
                        <p>{procedimiento.fecha_asignacion ? formatDate(procedimiento.fecha_asignacion) : 'No asignado'}</p>
                    </div>
                    <div className="fecha-item">
                        <p className="fecha-titulo">Aceptado</p>
                        <p>{procedimiento.fecha_asignacion ? formatDate(procedimiento.fecha_asignacion) : 'No aceptado'}</p>
                    </div>
                    <div className="fecha-item">
                        <p className="fecha-titulo">Entrega</p>
                        <p>{procedimiento.fecha_entrega_informe ? formatDate(procedimiento.fecha_entrega_informe) : 'No entregado'}</p>
                    </div>
                </div>

                <div className="detalles-otros">
                    <div className="detalle-item">
                        <strong>Calidad:</strong> {procedimiento.control_calidad || 'Pendiente'}
                    </div>
                    <div className="detalle-item">
                        <strong>Ratificación:</strong>{' '}
                        {procedimiento.ratificacion_solicitada === 'si'
                            ? `Solicitada el ${formatDate(procedimiento.fecha_ratificacion)}`
                            : 'No solicitada.'}
                    </div>
                    <div className="detalle-item">
                        <strong>
                            {procedimiento.especialidad_nombre === 'Peritación Excepcional'
                                ? 'Gratuita:'
                                : 'Miembros Evaluados:'}
                        </strong>{' '}
                        {procedimiento.especialidad_nombre === 'Peritación Excepcional'
                            ? 'No'
                            : procedimiento.numero_miembros_evaluados}
                    </div>

                    <div className="detalle-item">
                        <strong>Episodio IML:</strong>{' '}
                        {procedimiento.numero_episodio_iml || 'No disponible'}
                    </div>

                    {peritacionExcepcional && (
                        <>
                            <div className="detalle-item">
                                <strong>Gratuita:</strong>{' '}
                                {peritacionExcepcional.gratuita ? 'Sí' : 'No'}
                            </div>
                            <div className="detalle-item">
                                <strong>Fecha de Envío de Presupuesto:</strong>{' '}
                                {peritacionExcepcional.fecha_envio_presupuesto
                                    ? formatDate(peritacionExcepcional.fecha_envio_presupuesto)
                                    : 'No disponible'}
                            </div>
                            <div className="detalle-item">
                                <strong>Estado del Presupuesto:</strong>{' '}
                                {peritacionExcepcional.estado_presupuesto || '—'}
                            </div>
                            <div className="detalle-item">
                                <strong>Fecha de Aceptación:</strong>{' '}
                                {peritacionExcepcional.fecha_aceptacion_presupuesto
                                    ? formatDate(peritacionExcepcional.fecha_aceptacion_presupuesto)
                                    : 'No disponible'}
                            </div>
                        </>
                    )}

                </div>

                <div
                    className="valoraciones"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        position: 'relative', // necesitamos posicionar el botón 'absolute'
                    }}
                >
                    {/* Botón de editar valoraciones, visible si userRole !== 'Perito' */}
                    {userRole !== 'Perito' && (
                        <img
                            src={require('../assets/icons/edit.svg').default}
                            alt="Editar valoraciones"
                            style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                            }}
                            onClick={() => setIsValoracionesModalVisible(true)}
                        />
                    )}

                    {procedimiento.valoraciones && procedimiento.valoraciones.length > 0 ? (
                        procedimiento.valoraciones.length === 1 ? (
                            <>
                                <strong style={{ marginBottom: '8px' }}>Evaluaciones:</strong>
                                <p>
                                    Citación emitida para el día{' '}
                                    {formatDate(procedimiento.valoraciones[0].fecha_valoracion)}
                                </p>
                            </>
                        ) : (
                            <>
                                <strong style={{ marginBottom: '8px' }}>Evaluaciones:</strong>
                                <p>
                                    Citaciones emitidas para los días{' '}
                                    {procedimiento.valoraciones
                                        .map((v) => formatDate(v.fecha_valoracion))
                                        .join(', ')}
                                </p>
                            </>
                        )
                    ) : (
                        <>
                            <strong style={{ marginBottom: '8px' }}>Evaluaciones:</strong>
                            <p>No hay valoraciones.</p>
                        </>
                    )}
                </div>


                <h2 className="peritos-titulo">
                    Peritos Asignados
                    {userRole === 'Admin' && (
                        <img
                            src={require('../assets/icons/edit_experts.svg').default}
                            alt="Editar peritos asignados"
                            className="peritos-icon-inline"
                            onClick={handleEditPeritosClick}
                        />
                    )}
                </h2>

                <div className="peritos-asignados">
                    {procedimiento.peritos_asignados && procedimiento.peritos_asignados.length > 0 ? (
                        procedimiento.peritos_asignados.map((perito, index) => {
                            // Facturación por perito
                            const registroFactura = facturasPerito.find((f) => f.perito?.id === perito.id);
                            let iconoFacturacion;
                            if (!registroFactura) {
                                iconoFacturacion = require('../assets/icons/euro_red.svg').default;
                            } else {
                                const estado = registroFactura.estado_factura.toLowerCase().trim();
                                if (estado === 'enviada') {
                                    iconoFacturacion = require('../assets/icons/euro_orange.svg').default;
                                } else if (estado === 'aceptada') {
                                    iconoFacturacion = require('../assets/icons/euro_green.svg').default;
                                } else {
                                    iconoFacturacion = require('../assets/icons/euro_red.svg').default;
                                }
                            }
                            // Permisos
                            const puedeVerIcono =
                                userRole === 'Admin' || (userRole === 'Perito' && userId === perito.id);

                            return (
                                <p key={index} className="perito-nombre">
                                    {`${perito.nombre} ${perito.apellidos} (${perito.especialidad_pericial?.nombre || 'Sin especialidad'})`}
                                    {puedeVerIcono && (
                                        <img
                                            src={iconoFacturacion}
                                            alt={
                                                !registroFactura
                                                    ? 'No facturado'
                                                    : registroFactura.estado_factura.toLowerCase().trim() === 'enviada'
                                                        ? 'Facturado (Enviada)'
                                                        : registroFactura.estado_factura.toLowerCase().trim() === 'aceptada'
                                                            ? 'Facturado (Aceptada)'
                                                            : 'Facturado'
                                            }
                                            title={
                                                !registroFactura
                                                    ? 'No facturado'
                                                    : registroFactura.estado_factura.toLowerCase().trim() === 'enviada'
                                                        ? 'Facturado (Enviada)'
                                                        : registroFactura.estado_factura.toLowerCase().trim() === 'aceptada'
                                                            ? 'Facturado (Aceptada)'
                                                            : 'Facturado'
                                            }
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                marginLeft: '8px',
                                                cursor: userRole === 'Admin' ? 'pointer' : 'default',
                                            }}
                                            onClick={() => {
                                                if (userRole === 'Admin') {
                                                    openFacturacionPeritoModal(perito.id);
                                                }
                                            }}
                                        />
                                    )}
                                </p>
                            );
                        })
                    ) : (
                        <p>No hay peritos asignados.</p>
                    )}
                </div>
            </div>

            {/* Documentos */}
            <div className="documentos-procedimiento">
                <h3>Documentación</h3>
                <img
                    src={require('../assets/icons/download_all.svg').default}
                    alt="Descargar todos los documentos"
                    className="download-all-icon"
                    onClick={handleDownloadAll}
                />
                {documentos.length === 0 ? (
                    <p>No hay documentos subidos para este procedimiento.</p>
                ) : (
                    <ul className="documentos-list">
                        {documentos.map((doc) => {
                            const formattedDate = doc.fecha_subida ? formatDate(doc.fecha_subida) : '';
                            const isFakeDate = formattedDate === '12/12/24';

                            // Devuelve en doc.uploaded_by el ID del usuario o un objeto con doc.uploaded_by.id
                            // Depende de tu serializer. Asumamos doc.uploaded_by es un ID (número)
                            const canDelete = () => {
                                if (!doc.uploaded_by) return false;
                                if (doc.uploaded_by !== userId) return false;

                                // Check si no han pasado 60 min
                                const fechaSubida = new Date(doc.fecha_subida); // doc.fecha_subida en ISO
                                const ahora = new Date();
                                const diffMs = ahora - fechaSubida; // milisegundos
                                const diffHrs = diffMs / (1000 * 3600);
                                return diffHrs <= 1; // si <= 1, no han pasado 60 min
                            };


                            const handleDeleteDocumento = (doc) => {
                                confirm({
                                    title: '¿Eliminar este documento?',
                                    content: `Vas a eliminar definitivamente el documento "${doc.nombre}". Solo tienes 60 min desde la subida para borrarlo.`,
                                    okText: 'Sí, eliminar',
                                    okType: 'danger',
                                    cancelText: 'Cancelar',
                                    async onOk() {
                                        try {
                                            // DELETE /procedimientos/<proc_id>/documentos/<doc_id>/
                                            await axiosInstance.delete(
                                                `/procedimientos/${procedimiento.id}/documentos/${doc.id}/`
                                            );
                                            message.success('Documento borrado correctamente.');

                                            // Refrescar lista
                                            const respDocs = await axiosInstance.get(
                                                `procedimientos/${procedimiento.id}/documentos/`
                                            );
                                            setDocumentos(respDocs.data);
                                        } catch (error) {
                                            console.error('Error al eliminar documento:', error);
                                            const msg = error.response?.data?.error || 'Error al eliminar el documento';
                                            message.error(msg);
                                        }
                                    },
                                });
                            };

                            return (
                                <li key={doc.id}>
                                    <img
                                        src={getIconForExtension(doc.nombre)}
                                        alt="icon"
                                        className="document-icon"
                                    />
                                    {doc.es_informe && (
                                        <img
                                            src={require('../assets/icons/es_informe.svg').default}
                                            alt="Informe"
                                            className="document-icon-informe"
                                        />
                                    )}
                                    <a
                                        href={doc.archivo}
                                        download
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {doc.nombre}
                                        {doc.es_informe && ' (INFORME)'}
                                        {doc.es_citacion && ' (CITACIÓN)'}
                                        {doc.es_justificante && ' (JUSTIFICANTE)'}
                                    </a>
                                    {canDelete(doc) && (
                                        <img
                                            src={require('../assets/icons/trash_red.svg').default}
                                            alt="Eliminar"
                                            className="delete-document-icon"
                                            onClick={() => handleDeleteDocumento(doc)}
                                        />
                                    )}
                                    {doc.fecha_subida && !isFakeDate && (
                                        <span className="document-date">{formattedDate}</span>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}

                {userRole !== 'Perito' && (
                    <div className="upload-section">
                        <h4>Subir Documentos</h4>
                        <div
                            className="dropzone"
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onClick={() => document.getElementById('fileInputGeneral').click()}
                        >
                            Arrastra y suelta archivos aquí o haz clic para subir
                        </div>
                        <input
                            id="fileInputGeneral"
                            type="file"
                            style={{ display: 'none' }}
                            multiple
                            onChange={(e) => handleFileUpload(e)}
                        />
                        {uploadError && <p className="upload-error">{uploadError}</p>}
                    </div>
                )}

                {(userRole === 'Perito' || userRole === 'Admin') && (
                    <div className="upload-section">
                        <h4>Subir Informe Pericial</h4>
                        <div
                            className="dropzone"
                            onDrop={(event) => handleDrop(event, true)}
                            onDragOver={handleDragOver}
                            onClick={() => document.getElementById('fileInputInforme').click()}
                        >
                            Arrastra y suelta archivos aquí o haz clic para subir
                        </div>
                        <input
                            id="fileInputInforme"
                            type="file"
                            style={{ display: 'none' }}
                            multiple
                            onChange={(e) => handleFileUpload(e, true)}
                        />
                        {uploadError && <p className="upload-error">{uploadError}</p>}
                    </div>
                )}

                {userRole !== 'Perito' && (
                    <div className="upload-section">
                        <h4>Subir Citación (Ratificación)</h4>
                        <div
                            className="dropzone"
                            onDrop={(event) => handleDrop(event, false, true)}
                            onDragOver={handleDragOver}
                            onClick={() => document.getElementById('fileInputCitacion').click()}
                        >
                            Arrastra y suelta archivos aquí o haz clic para subir
                        </div>
                        <input
                            id="fileInputCitacion"
                            type="file"
                            style={{ display: 'none' }}
                            multiple
                            onChange={(e) => handleFileUpload(e, false, true)}
                        />
                        {uploadError && <p className="upload-error">{uploadError}</p>}
                    </div>
                )}

                {(userRole === 'Perito' || userRole === 'Admin' || userRole === 'Juzgado') && (
                    <div className="upload-section">
                        <h4>Subir Justificante</h4>
                        <div
                            className="dropzone"
                            onDrop={(event) => handleDrop(event, false, false, true)}
                            onDragOver={handleDragOver}
                            onClick={() => document.getElementById('fileInputJustificante').click()}
                        >
                            Arrastra y suelta archivos aquí o haz clic para subir
                        </div>
                        <input
                            id="fileInputJustificante"
                            type="file"
                            style={{ display: 'none' }}
                            multiple
                            onChange={(e) => handleFileUpload(e, false, false, true)}
                        />
                        {uploadError && <p className="upload-error">{uploadError}</p>}
                    </div>
                )}

                {userRole !== 'Perito' && hasInformeSelected && (
                    <button
                        className="download-informes-button"
                        onClick={handleDownloadInforme}
                    >
                        Descargar Informe/s
                    </button>
                )}


                {(userRole === 'Perito' || userRole === 'Admin') && !isInformeEntregadoODescargado && (
                    <button
                        className="notificar-incomparecencia-button"
                        onClick={() => setIsIncomparecenciaModalVisible(true)}
                    >
                        Notificar Incomparecencia
                    </button>
                )}

                {(userRole === 'Perito' || userRole === 'Admin') && !isInformeEntregadoODescargado && (
                    <button
                        className="incidencia-button"
                        onClick={() => setIsIncidenciaModalVisible(true)}
                    >
                        Incidencias
                    </button>
                )}

                {(userRole === 'AdmPub' || userRole === 'Admin') && !isInformeEntregadoODescargado && (
                    <button
                        className="anular-button"
                        onClick={handleClickAnular}
                    >
                        Anular
                    </button>
                )}
            </div>

            {/* ---------- SUB-MODALES EXACTAMENTE IGUAL QUE EN PLATFORM ---------- */}
            {isEditPopupVisible && (
                <EditarProcedimientoModal
                    visible={isEditPopupVisible}
                    procedimiento={procedimiento}
                    onClose={() => setIsEditPopupVisible(false)}
                    onUpdate={(updatedProc) => {
                        setProcedimiento(updatedProc);
                        setIsEditPopupVisible(false);
                    }}
                />
            )}

            {isNotasPopupVisible && (
                <EditarNotasPopup
                    visible={isNotasPopupVisible}
                    procedimiento={procedimiento}
                    onClose={() => setIsNotasPopupVisible(false)}
                    onUpdate={(updated) => {
                        setProcedimiento(updated);
                        setIsNotasPopupVisible(false);
                    }}
                />
            )}

            {isPeritosPopupVisible && (
                <EditarPeritosModal
                    visible={isPeritosPopupVisible}
                    procedimiento={procedimiento}
                    onClose={() => setIsPeritosPopupVisible(false)}
                    onUpdate={(updated) => {
                        setProcedimiento(updated);
                        setIsPeritosPopupVisible(false);
                    }}
                />
            )}

            {/* Incomparecencia */}
            <Modal
                title="Confirmar Incomparecencia"
                visible={isIncomparecenciaModalVisible}
                onCancel={() => setIsIncomparecenciaModalVisible(false)}
                onOk={() => formIncomparecencia.submit()}
                okText="Confirmar"
                cancelText="Cancelar"
            >
                <Form
                    form={formIncomparecencia}
                    layout="vertical"
                    onFinish={handleConfirmIncomparecencia}
                >
                    <Form.Item
                        label="Información sobre nueva cita (obligatorio)"
                        name="comentario_nueva_cita"
                        rules={[{ required: true, message: 'Por favor, indique los detalles de la nueva cita o comentario.' }]}
                    >
                        <Input.TextArea rows={4} placeholder="Indique si hay nueva cita, fecha, etc." />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Incidencia */}
            <Modal
                title="Notificar Incidencia"
                visible={isIncidenciaModalVisible}
                onCancel={() => setIsIncidenciaModalVisible(false)}
                onOk={() => formIncidencia.submit()}
                okText="Confirmar"
                cancelText="Cancelar"
            >
                <Form
                    form={formIncidencia}
                    layout="vertical"
                    onFinish={handleConfirmIncidencia}
                >
                    <Form.Item
                        label="Motivo de la Incidencia"
                        name="motivo_incidencia"
                        rules={[{ required: true, message: 'Por favor, describa la incidencia.' }]}
                    >
                        <Input.TextArea rows={4} placeholder="Describa la incidencia..." />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Facturación a la Administración */}
            <Modal
                title="Facturación a la Administración"
                visible={isFacturacionModalVisible}
                onCancel={() => setIsFacturacionModalVisible(false)}
                onOk={() => formFacturacion.submit()}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form
                    form={formFacturacion}
                    layout="vertical"
                    onFinish={handleFacturacionSubmit}
                >
                    <Form.Item
                        label="¿Facturado?"
                        name="facturado_administracion"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label="Fecha de Factura"
                        name="fecha_factura_administracion"
                    >
                        <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Previsualización del Informe"
                visible={isPreviewModalVisible}
                onCancel={() => setIsPreviewModalVisible(false)}
                footer={null}
                width={900} // un ancho mayor
            >
                <div style={{ display: 'flex', gap: '16px' }}>
                    {/* Columna Izquierda: Preview */}
                    <div style={{ flex: 1, borderRight: '1px solid #ddd', paddingRight: '16px' }}>
                        <h4>Vista previa (3 primeras páginas)</h4>
                        {previewPdfUrl ? (
                            <Document file={previewPdfUrl} onLoadError={(err) => console.error(err)}>
                                <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
                                <Page pageNumber={2} renderTextLayer={false} renderAnnotationLayer={false} />
                                <Page pageNumber={3} renderTextLayer={false} renderAnnotationLayer={false} />
                            </Document>
                        ) : (
                            <p>No se pudo cargar el informe para previsualizar.</p>
                        )}
                        <p style={{ marginTop: '12px', fontStyle: 'italic', color: 'gray' }}>
                            Esta es sólo una previsualización de las primeras páginas. Para descargar el informe completo, sube tu justificante firmado.
                        </p>
                    </div>

                    {/* Columna Derecha: Mensaje + Botón Descarga Justificante + Subida Justificante */}
                    <div style={{ flex: 1 }}>
                        <h3>Necesitas subir tu justificante firmado</h3>
                        <p>
                            Para descargar el informe completo, sube primero el justificante
                            firmado por el/la Letrado/a de la Administración de Justicia.
                        </p>

                        {/* Botón para descargar la plantilla del justificante (el doc es_justificante subido por otro) */}
                        {renderDescargaJustificante()}

                        <br />
                        <br />
                        <h4>Subir Justificante Firmado:</h4>
                        <div
                            className="dropzone"
                            onDrop={(event) => handleDropJustificante(event)}
                            onDragOver={(event) => event.preventDefault()}
                            onClick={() => document.getElementById('fileInputJustificanteJuzgado').click()}
                            style={{
                                border: '2px dashed #ccc',
                                padding: '20px',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            Arrastra y suelta aquí el justificante firmado, o haz clic para subir.
                        </div>
                        <input
                            id="fileInputJustificanteJuzgado"
                            type="file"
                            style={{ display: 'none' }}
                            multiple={false}
                            onChange={(e) => handleUploadJustificante(e)}
                        />
                    </div>
                </div>
            </Modal>


            {/* Facturación del Perito */}
            <Modal
                title="Facturación del Perito"
                visible={isFacturacionPeritoModalVisible}
                onCancel={closeFacturacionPeritoModal}
                onOk={() => formFacturacionPerito.submit()}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form
                    form={formFacturacionPerito}
                    layout="vertical"
                    onFinish={async (values) => {
                        try {
                            const registroExistente = facturasPerito.find(
                                (f) => f.perito?.id === selectedPeritoIdForFacturacion
                            );

                            let fechaISO = null;
                            if (values.fecha_factura) {
                                fechaISO = values.fecha_factura.toISOString();
                            }

                            if (registroExistente) {
                                // PATCH
                                await axiosInstance.patch(
                                    `/facturas-perito/${registroExistente.id}/`,
                                    {
                                        facturado: values.facturado,
                                        fecha_factura: fechaISO,
                                    }
                                );
                            } else {
                                // POST
                                await axiosInstance.post(
                                    '/facturas-perito/',
                                    {
                                        procedimiento: procedimiento.id,
                                        perito: selectedPeritoIdForFacturacion,
                                        facturado: values.facturado,
                                        fecha_factura: fechaISO,
                                    }
                                );
                            }

                            // Recargar facturas
                            const resp = await axiosInstance.get(
                                `/facturas-perito/?procedimiento=${procedimiento.id}`
                            );
                            setFacturasPerito(resp.data);

                            message.success('Facturación guardada correctamente.');
                            closeFacturacionPeritoModal();
                        } catch (error) {
                            console.error('Error guardando facturación perito:', error);
                            message.error('No se pudo guardar la facturación.');
                        }
                    }}
                >
                    <Form.Item
                        label="¿Facturado?"
                        name="facturado"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label="Fecha de Factura"
                        name="fecha_factura"
                    >
                        <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>

            <EditarValoracionesModal
                visible={isValoracionesModalVisible}
                onClose={() => setIsValoracionesModalVisible(false)}
                procedimientoId={procedimiento.id}
                onValoracionesUpdated={() => {
                    setIsValoracionesModalVisible(false);
                    reloadProcedimiento();
                }}
            />

            <MensajesProcedimientoModal
                visible={isChatModalVisible}
                onClose={() => setIsChatModalVisible(false)}
                procedimientoId={procedimiento.id}
            />
        </div>

    );
}

export default ExpedienteDetail;
