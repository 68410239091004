// Notificaciones.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Switch, message, Row, Col } from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import axiosInstance from '../services/axiosConfig';
import ExpedienteDetail from './ExpedienteDetail'; // Para mostrar detalles en modal

// Mapeo de estado del procedimiento
const estadoMap = {
    solicitado: 'Solicitado',
    asignado_peritos: 'Asignado Peritos',
    informe_entregado: 'Informe Entregado',
    pendiente_descarga: 'Pendiente Descarga',
    informe_descargado: 'Informe Descargado',
    anulado: 'Anulado',
    incomparecencia: 'Incomparecencia',
};

function Notificaciones() {
    const [tareas, setTareas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
    const [selectedProcedimientoId, setSelectedProcedimientoId] = useState(null);
    const [isNewTaskModalVisible, setIsNewTaskModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [filterPendientes, setFilterPendientes] = useState(false);

    // Cargar tareas
    const fetchTareas = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/notificaciones/');
            setTareas(response.data);
        } catch (error) {
            console.error('Error al cargar notificaciones:', error);
            message.error('No se pudieron cargar las notificaciones');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTareas();
    }, []);

    // Filtrado local: si "filterPendientes" está activado, mostramos solo tareas pendientes
    const tareasFiltradas = filterPendientes
        ? tareas.filter((t) => t.estado_tarea === 'pendiente')
        : tareas;

    // Cambiar estado de tarea (toggle entre pendiente y completada)
    const handleToggleEstado = async (tarea) => {
        const nuevoEstado = tarea.estado_tarea === 'pendiente' ? 'completada' : 'pendiente';
        try {
            await axiosInstance.patch(`/notificaciones/${tarea.id}/`, { estado_tarea: nuevoEstado });
            message.success('Estado actualizado correctamente');
            fetchTareas();
        } catch (error) {
            console.error('Error al actualizar estado de tarea:', error);
            message.error('No se pudo actualizar el estado de la tarea');
        }
    };

    // Confirmación y eliminación de tarea
    const confirmDeleteTask = (tarea) => {
        Modal.confirm({
            title: 'Eliminar tarea',
            content: '¿Estás seguro de que deseas eliminar esta tarea?',
            okText: 'Sí, eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk: () => handleDeleteTask(tarea),
        });
    };

    const handleDeleteTask = async (tarea) => {
        try {
            await axiosInstance.delete(`/notificaciones/${tarea.id}/`);
            message.success('Tarea eliminada correctamente');
            fetchTareas();
        } catch (error) {
            console.error('Error al eliminar tarea:', error);
            message.error('No se pudo eliminar la tarea');
        }
    };

    // Abrir modal con detalles del procedimiento
    const openDetalleModal = (procedimientoId) => {
        setSelectedProcedimientoId(procedimientoId);
        setIsDetailModalVisible(true);
    };

    // Función para crear tarea nueva manualmente
    const handleCreateTask = async (values) => {
        try {
            await axiosInstance.post('/notificaciones/', values);
            message.success('Tarea creada correctamente');
            setIsNewTaskModalVisible(false);
            form.resetFields();
            fetchTareas();
        } catch (error) {
            console.error('Error al crear tarea:', error);
            message.error('No se pudo crear la tarea');
        }
    };

    const columns = [
        {
            title: 'ID Proc',
            dataIndex: 'procedimiento_id',
            key: 'procedimiento_id',
            width: 80,
        },
        {
            title: 'Estado Proc',
            dataIndex: 'estado_procedimiento',
            key: 'estado_procedimiento',
            width: 150,
            render: (text) => estadoMap[text] || text,
        },
        {
            title: 'Tipo de Tarea',
            dataIndex: 'tipo_tarea',
            key: 'tipo_tarea',
            width: 150,
        },
        {
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            ellipsis: true,
            width: 400,
        },
        {
            title: 'Peritos',
            dataIndex: 'peritos_asignados',
            key: 'peritos_asignados',
            render: (arr) => arr.join(', '),
            width: 200,
        },
        {
            title: 'Estado Tarea',
            dataIndex: 'estado_tarea',
            key: 'estado_tarea',
            render: (text, record) => (
                <Switch
                    style={{ transform: 'scale(1.2)' }}
                    checked={record.estado_tarea === 'completada'}
                    onChange={() => handleToggleEstado(record)}
                    checkedChildren="Ok"
                    unCheckedChildren="Pend."
                />
            ),
            width: 100,
        },
        {
            title: 'Acciones',
            key: 'acciones',
            render: (record) => (
                <div style={{ display: 'flex', gap: '4px' }}>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() => openDetalleModal(record.procedimiento_id)}
                        title="Ver detalles del procedimiento"
                        size="small"
                    />
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => confirmDeleteTask(record)}
                        title="Eliminar tarea"
                        danger
                        size="small"
                    />
                </div>
            ),
            width: 80,
        },
    ];

    return (
        <div style={{ padding: '16px' }}>
            <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
                <Col>
                    <h2 style={{ margin: 0 }}>Notificaciones</h2>
                </Col>
                <Col>
                    <Row gutter={16} align="middle">
                        <Col>
                            <Switch
                                style={{ transform: 'scale(1.2)' }}
                                checked={filterPendientes}
                                onChange={setFilterPendientes}
                                checkedChildren="Solo pendientes"
                                unCheckedChildren="Todas"
                            />
                        </Col>
                        <Col>
                            <Button type="primary" onClick={() => setIsNewTaskModalVisible(true)}>
                                Añadir Tarea Nueva
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Table
                dataSource={tareasFiltradas}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 10 }}
            />

            {/* Modal para ver detalles del procedimiento – ancho aumentado */}
            <Modal
                visible={isDetailModalVisible}
                title="Detalles del Procedimiento"
                onCancel={() => setIsDetailModalVisible(false)}
                footer={null}
                width={1600}
            >
                {selectedProcedimientoId && <ExpedienteDetail procedimientoId={selectedProcedimientoId} />}
            </Modal>

            {/* Modal para crear tarea nueva */}
            <Modal
                visible={isNewTaskModalVisible}
                title="Crear Nueva Tarea"
                onCancel={() => setIsNewTaskModalVisible(false)}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            handleCreateTask(values);
                        })
                        .catch((info) => {
                            console.log('Validación fallida:', info);
                        });
                }}
                okText="Crear"
                cancelText="Cancelar"
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="procedimiento"
                        label="ID del Procedimiento"
                        rules={[{ required: true, message: 'Ingresa el ID del procedimiento' }]}
                    >
                        <Input type="number" placeholder="Ej. 314" />
                    </Form.Item>
                    <Form.Item
                        name="tipo_tarea"
                        label="Tipo de Tarea"
                        rules={[{ required: true, message: 'Ingresa el tipo de tarea' }]}
                    >
                        <Input placeholder="Ej. Incidencia, Citación, etc." />
                    </Form.Item>
                    <Form.Item
                        name="descripcion"
                        label="Descripción"
                        rules={[{ required: true, message: 'Ingresa la descripción de la tarea' }]}
                    >
                        <Input.TextArea rows={3} placeholder="Detalle de la tarea" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Notificaciones;
