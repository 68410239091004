// src/components/Header.js

import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';

const Header = () => {
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        // Obtener información del usuario, incluyendo el rol, desde el backend
        axiosInstance.get('/mi_usuario/')
            .then(response => {
                // Se asume que el backend devuelve { role: 'Admin', ... }
                setUserRole(response.data.role);
            })
            .catch(error => {
                console.error('Error al obtener info del usuario:', error);
            });
    }, []);

    return (
        <header className="plataforma-header modern-header">
            <div className="logo-container">
                <img
                    src={require('../assets/images/logo.svg').default}
                    alt="Company Logo"
                    className="header-logo"
                />
            </div>
            <div className="header-content">
                <div className="company-info">
                    <h1 className="company-name"><strong>AF Peritaciones</strong></h1>
                    <p className="company-slogan">
                        Plataforma Integral de Gestión de Peritaciones Judiciales Forenses
                    </p>
                </div>
                <nav className="menu-horizontal">
                    <Link to="/plataforma">Mis Procedimientos</Link>
                    <Link to="/resumen-estadistico">Resumen Estadístico</Link>
                    <Link to="/portal-privado">Portal Privado</Link>
                    <a
                        href="/"
                        onClick={() => {
                            localStorage.removeItem('authToken');
                            window.location.href = '/';
                        }}
                    >
                        Cerrar Sesión
                    </a>
                </nav>
            </div>
        </header>
    );
};

export default Header;
