// FacturaAdminList.js
import React, { useState, useEffect } from 'react';
import {
    Table,
    Button,
    Modal,
    message,
    Input,
    DatePicker
} from 'antd';
import { DownloadOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axiosInstance from '../services/axiosConfig';
import FacturaAdminForm from './FacturaAdminForm';

const { RangePicker } = DatePicker;

/**
 * Exporta un array de objetos a CSV; descarga un archivo "facturas.csv"
 */
function exportToCSV(data) {
    if (!data || !data.length) {
        message.warning('No hay datos para exportar.');
        return;
    }
    const headers = [
        'Número Factura',
        'Fecha Emisión',
        'Provincia',
        'Contrato Público',
        'Estado',
        'Procedimientos'
    ];

    // Convertimos cada fila a CSV (escapando comas si fuera necesario)
    const rows = data.map((fact) => {
        const num = fact.numero_factura || '';
        const fecha = fact.fecha_emision || '';
        const prov = fact.provincia?.nombre || '';
        const contrato = fact.contrato_publico?.numero_contrato || '';
        const estado = fact.estado || '';
        // IDs de procedimientos
        const procs = (fact.procedimientos || []).map((p) => p.id).join(';');
        // Escapar comas con " si fuera necesario:
        // pero aquí simple
        return [num, fecha, prov, contrato, estado, procs].join(',');
    });

    const csvContent = [
        headers.join(','), // cabeceras
        ...rows
    ].join('\n');

    // Creamos un blob y forzamos la descarga
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'facturas.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

const FacturaAdminList = () => {
    const [facturas, setFacturas] = useState([]);
    const [filteredFacturas, setFilteredFacturas] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFactura, setSelectedFactura] = useState(null);

    // Estados para filtros
    const [searchText, setSearchText] = useState('');
    const [dateRange, setDateRange] = useState([]); // [startDate, endDate] (dayjs)

    const fetchFacturas = async () => {
        try {
            const response = await axiosInstance.get('/facturas-administracion/');
            setFacturas(response.data);
        } catch (error) {
            message.error('Error al cargar las facturas');
        }
    };

    useEffect(() => {
        fetchFacturas();
    }, []);

    // Cada vez que cambie facturas, searchText o dateRange, filtramos
    useEffect(() => {
        applyFilters();
    }, [facturas, searchText, dateRange]);

    const applyFilters = () => {
        let data = [...facturas];

        // Filtrado por texto (Provincia, Número de Factura, ID de procedimiento)
        if (searchText.trim()) {
            const lower = searchText.toLowerCase();

            data = data.filter((item) => {
                // 1) Buscar en provincia
                const prov = item.provincia?.nombre?.toLowerCase() || '';
                if (prov.includes(lower)) return true;

                // 2) Buscar en número_factura
                const num = (item.numero_factura || '').toLowerCase();
                if (num.includes(lower)) return true;

                // 3) Buscar en IDs de procedimientos
                //    Factura tiene item.procedimientos = array
                //    Comprobamos si alguno coincide con la parte textual
                if (item.procedimientos?.some((p) => String(p.id).includes(lower))) {
                    return true;
                }

                return false;
            });
        }

        // Filtrado por rango de fechas en "fecha_emision"
        if (dateRange.length === 2) {
            const [start, end] = dateRange;
            data = data.filter((item) => {
                if (!item.fecha_emision) return false;
                // Asumimos item.fecha_emision es "YYYY-MM-DD"
                const d = dayjs(item.fecha_emision, 'YYYY-MM-DD');
                // Ver si d está entre start y end (inclusive)
                return d.isBetween(start.startOf('day'), end.endOf('day'), null, '[]');
            });
        }

        setFilteredFacturas(data);
    };

    const handleDownload = (record) => {
        if (record.archivo_factura) {
            window.open(record.archivo_factura, '_blank');
        } else {
            message.info('No hay archivo de factura disponible');
        }
    };

    const handleEdit = (record) => {
        setSelectedFactura(record);
        setIsModalVisible(true);
    };

    const columns = [
        { title: 'Número Factura', dataIndex: 'numero_factura', key: 'numero_factura' },
        { title: 'Fecha Emisión', dataIndex: 'fecha_emision', key: 'fecha_emision' },
        {
            title: 'Provincia',
            dataIndex: 'provincia',
            key: 'provincia',
            render: (prov) => prov ? prov.nombre : ''
        },
        {
            title: 'Contrato Público',
            dataIndex: 'contrato_publico',
            key: 'contrato_publico',
            render: (cp) => cp ? cp.numero_contrato : ''
        },
        { title: 'Estado', dataIndex: 'estado', key: 'estado' },
        {
            title: 'Procedimientos',
            dataIndex: 'procedimientos',
            key: 'procedimientos',
            render: (procs) => procs.map((p) => p.id).join(', ')
        },
        {
            title: 'Acciones',
            key: 'acciones',
            render: (record) => (
                <div style={{ whiteSpace: 'nowrap' }}>
                    <DownloadOutlined
                        onClick={() => handleDownload(record)}
                        style={{ marginRight: 8, cursor: 'pointer' }}
                        title="Descargar Archivo Factura"
                    />
                    <EditOutlined
                        onClick={() => handleEdit(record)}
                        style={{ cursor: 'pointer' }}
                        title="Editar Factura"
                    />
                </div>
            ),
        },
    ];

    return (
        <>
            <h2>Facturación a la Administración</h2>
            <div style={{ marginBottom: 16, display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                <Input
                    prefix={<SearchOutlined />}
                    placeholder="Buscar por provincia, #factura o ID procedimiento"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ width: 350 }}
                />
                <RangePicker
                    format="DD-MM-YYYY"
                    value={dateRange}
                    onChange={(vals) => setDateRange(vals || [])}
                />
                <Button onClick={() => exportToCSV(filteredFacturas)} type="primary">
                    Exportar Excel
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={filteredFacturas}
                rowKey="id"
            />

            <Modal
                visible={isModalVisible}
                title="Editar Factura Administración"
                footer={null}
                onCancel={() => { setIsModalVisible(false); setSelectedFactura(null); }}
            >
                <FacturaAdminForm
                    factura={selectedFactura}
                    onSuccess={() => {
                        setIsModalVisible(false);
                        setSelectedFactura(null);
                        fetchFacturas();
                    }}
                />
            </Modal>
        </>
    );
};

export default FacturaAdminList;
