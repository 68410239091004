// PdtsFacturarList.js
import React, { useEffect, useState } from 'react';
import { Card, Select, Table, message, Button, Input, DatePicker } from 'antd';
import axiosInstance from '../services/axiosConfig';
import * as XLSX from 'xlsx';  // Librería para exportar a Excel
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const { Option } = Select;
const { RangePicker } = DatePicker;

const estadoMap = {
    solicitado: 'Solicitado',
    asignado_peritos: 'Asignado Peritos',
    informe_entregado: 'Informe Entregado',
    pendiente_descarga: 'Pendiente Descarga',
    informe_descargado: 'Informe Descargado',
    anulado: 'Anulado',
    incomparecencia: 'Incomparecencia',
};

function PdtsFacturarList() {
    const [provincias, setProvincias] = useState([]);
    const [procedimientos, setProcedimientos] = useState([]);      // Datos originales
    const [filteredData, setFilteredData] = useState([]);          // Datos filtrados en frontend
    const [selectedProv, setSelectedProv] = useState(null);

    // Filtros locales
    const [searchText, setSearchText] = useState('');              // texto para ID, procedimiento, especialidad, juzgado
    const [dateRange, setDateRange] = useState([]);                // Para filtrar por fecha_entrega_informe

    useEffect(() => {
        // 1) Cargar provincias
        axiosInstance.get('/provincias/')
            .then((res) => setProvincias(res.data))
            .catch(() => message.error('Error al cargar provincias'));

        // 2) Cargar lista inicial (sin filtro de provincia)
        fetchProcedimientos(null);
    }, []);

    // Cuando cambie la provincia, recargamos desde servidor
    const handleProvinciaChange = (value) => {
        setSelectedProv(value || null);
        fetchProcedimientos(value);
    };

    // Petición al servidor con ?provincia_id
    const fetchProcedimientos = async (provId) => {
        try {
            let url = '/facturas-administracion/pendientes_para_factura/';
            if (provId) {
                url += `?provincia_id=${provId}`;
            }
            const res = await axiosInstance.get(url);
            setProcedimientos(res.data);
        } catch (error) {
            message.error('Error al cargar procedimientos pendientes de facturar');
        }
    };

    // Cada vez que cambie `procedimientos`, `searchText` o `dateRange`, aplicamos filtrado local
    useEffect(() => {
        applyFilters();
    }, [procedimientos, searchText, dateRange]);

    // Función de filtrado local
    const applyFilters = () => {
        let data = [...procedimientos];

        // 1) Filtro por texto: ID, numero_procedimiento, especialidad_nombre, juzgado_nombre
        if (searchText.trim()) {
            const lower = searchText.toLowerCase();
            data = data.filter(item => {
                const idStr = String(item.id).toLowerCase();
                const procStr = (item.numero_procedimiento || '').toLowerCase();
                const espStr = (item.especialidad_nombre || '').toLowerCase();
                const juzStr = (item.juzgado_nombre || '').toLowerCase();

                return (
                    idStr.includes(lower) ||
                    procStr.includes(lower) ||
                    espStr.includes(lower) ||
                    juzStr.includes(lower)
                );
            });
        }

        // 2) Filtro por rango de fecha en "fecha_entrega_informe"
        if (dateRange.length === 2) {
            const [start, end] = dateRange;
            data = data.filter(item => {
                if (!item.fecha_entrega_informe) return false;
                // item.fecha_entrega_informe es algo tipo "2025-02-05T13:00:00Z"
                // Convertimos con dayjs
                const d = dayjs(item.fecha_entrega_informe);
                // Comprobamos si está dentro (inclusive)
                return d.isBetween(start.startOf('day'), end.endOf('day'), null, '[]');
            });
        }

        setFilteredData(data);
    };

    // ---- Exportar a Excel ----
    const handleExportExcel = () => {
        try {
            // Transformamos `filteredData` en un array de objetos "planos"
            const dataForExcel = filteredData.map((item) => ({
                'ID': item.id,
                'Procedimiento': item.numero_procedimiento,
                'Estado': estadoMap[item.estado] || item.estado,
                'Especialidad': item.especialidad_nombre || '',
                'Juzgado': item.juzgado_nombre,
                'Provincia': item.provincia_nombre,
                'N.º Miembros': item.numero_miembros_evaluados,
                'Fecha Entrega': item.fecha_entrega_informe
                    ? new Date(item.fecha_entrega_informe).toLocaleString()
                    : '',
                'Peritos': item.peritos_asignados_nombres || '',
            }));

            // 1) Creamos la hoja (worksheet) a partir de dataForExcel
            const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
            // 2) Creamos un workbook y agregamos la hoja
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Pendientes_Facturar');

            // 3) Generamos el archivo y forzamos la descarga
            XLSX.writeFile(workbook, 'pendientes_facturar.xlsx');
        } catch (error) {
            console.error('Error exportando a Excel:', error);
            message.error('Error al exportar Excel');
        }
    };

    const handleDownloadJustificantes = async () => {
        if (filteredData.length === 0) {
            message.info("No hay procedimientos en la lista.");
            return;
        }
        // Extraemos los IDs de los procedimientos de la lista filtrada
        const procIds = filteredData.map(proc => proc.id).join(',');
        try {
            // Realizamos la request al endpoint, indicando responseType 'blob'
            const response = await axiosInstance.get(
                `/descargar_justificantes_pendientes/?procedimientos=${procIds}`,
                { responseType: 'blob' }
            );
            // Crear URL temporal para descargar el archivo
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'justificantes_pendientes.zip');
            document.body.appendChild(link);
            link.click();
            // Limpieza: remover el link y revocar el URL
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error al descargar justificantes:", error);
            message.error("Error al descargar justificantes.");
        }
    };


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Procedimiento',
            dataIndex: 'numero_procedimiento',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            render: (val) => estadoMap[val] || val,
        },
        {
            title: 'Especialidad',
            dataIndex: 'especialidad_nombre',
            render: (val) => val || '',
        },
        {
            title: 'Juzgado',
            dataIndex: 'juzgado_nombre',
        },
        {
            title: 'Provincia',
            dataIndex: 'provincia_nombre',
        },
        {
            title: 'N.º Miembros',
            dataIndex: 'numero_miembros_evaluados',
        },
        {
            title: 'Fecha Entrega',
            dataIndex: 'fecha_entrega_informe',
            render: (val) => val
                ? new Date(val).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                })
                : '',
        },
        {
            title: 'Peritos',
            dataIndex: 'peritos_asignados_nombres',
            render: (val) => val || '',
        },
    ];

    return (
        <Card title="Procedimientos Pendientes de Facturar a la Administración">

            {/* Controles de filtro (provincia, search text, date range, export) */}
            <div style={{ marginBottom: 16, display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                {/* Select de provincia (cambia la request al backend) */}
                <Select
                    allowClear
                    style={{ width: 180 }}
                    placeholder="provincia"
                    value={selectedProv}
                    onChange={handleProvinciaChange}
                >
                    {provincias.map((prov) => (
                        <Option key={prov.id} value={prov.id}>
                            {prov.nombre}
                        </Option>
                    ))}
                </Select>

                {/* Input de búsqueda por ID, Procedimiento, Especialidad, Juzgado */}
                <Input
                    placeholder="Buscar (ID, proc., esp., juzg.)"
                    style={{ width: 220 }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />

                {/* Filtro por rango de fechas en fecha_entrega_informe */}
                <RangePicker
                    format="DD-MM-YYYY"
                    value={dateRange}
                    onChange={(vals) => setDateRange(vals || [])}
                />

                {/* Botón para exportar Excel */}
                <Button onClick={handleExportExcel} type="primary">
                    Exportar Excel
                </Button>

                <Button onClick={handleDownloadJustificantes} type="primary">
                    Descargar justificantes
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={filteredData}
                rowKey="id"
            />
        </Card>
    );
}

export default PdtsFacturarList;
