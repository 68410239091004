// FacturacionPage.js
import React, { useState, useEffect } from 'react';
import {
    Layout,
    Menu,
    Table,
    Button,
    Form,
    Input,
    DatePicker,
    Upload,
    Select,
    Modal,
    message,
    Badge,
} from 'antd';
import {
    UploadOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    EditOutlined,
    EyeOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import axiosInstance from '../services/axiosConfig';
import Header from './Header';
import FacturaAdminList from './FacturaAdminList';
import FacturaAdminCreate from './FacturaAdminCreate';
import PdtsFacturarList from './PdtsFacturarList';
import CalendarioPage from './CalendarioPage';
import PrivatePortalDashboard from "./PrivatePortalDashboard";
import InformesEstadisticos from './InformesEstadisticos';
import Notificaciones from './Notificaciones';
import ExpedienteDetail from './ExpedienteDetail';
import ListadoProcedimientos from './ListadoProcedimientos';


const { Sider, Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;


function FacturacionPage() {
    // ----- ESTADOS GENERALES -----
    const [collapsed, setCollapsed] = useState(false);
    // Pestañas disponibles: 'mis-facturas', 'nueva-factura', 'pendientes', 'calidad'
    const [selectedKey, setSelectedKey] = useState('portal-privado');

    // ----- DATOS DE LISTAS -----
    const [misFacturas, setMisFacturas] = useState([]);
    const [procedimientosDisponibles, setProcedimientosDisponibles] = useState([]);
    const [pendientes, setPendientes] = useState([]);
    const [procedimientosCalidad, setProcedimientosCalidad] = useState([]);

    // ----- USUARIO Y PERITOS -----
    const [userRole, setUserRole] = useState(null);
    const [peritos, setPeritos] = useState([]);

    // ----- FORMULARIOS -----
    const [formFactura] = Form.useForm(); // "Nueva Factura"
    // Modales de incidencias y edición
    const [formIncidence] = Form.useForm();
    const [formAdminQuality] = Form.useForm();
    const [formInvoiceEdit] = Form.useForm();
    const [formInvoiceIncidence] = Form.useForm();

    // Estados para modales
    const [isIncidenceModalVisible, setIsIncidenceModalVisible] = useState(false);
    const [isAdminQualityModalVisible, setIsAdminQualityModalVisible] = useState(false);
    const [selectedQualityProc, setSelectedQualityProc] = useState(null);

    const [isInvoiceEditModalVisible, setIsInvoiceEditModalVisible] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    const [isInvoiceIncidenceModalVisible, setIsInvoiceIncidenceModalVisible] = useState(false);
    const [selectedInvoiceIncidence, setSelectedInvoiceIncidence] = useState(null);

    // ----- FILTROS "MIS FACTURAS" (automáticos) -----
    // Guardamos en estado el valor de cada filtro; se hace fetch cada vez que cambian
    const [filterPerito, setFilterPerito] = useState(null);         // Solo Admin
    const [filterProcedimiento, setFilterProcedimiento] = useState('');
    const [filterNumeroFactura, setFilterNumeroFactura] = useState('');
    const [filterDateRange, setFilterDateRange] = useState([]);
    const [filterEstadoFactura, setFilterEstadoFactura] = useState('');

    // Filtros para "Pendientes de Facturar"
    const [pendFilterPerito, setPendFilterPerito] = useState(null);     // Solo Admin
    const [pendFilterSearch, setPendFilterSearch] = useState('');       // Búsqueda por proc, id o juzgado
    const [pendFilterProv, setPendFilterProv] = useState('');           // Provincia
    const [provinces, setProvinces] = useState([]);

    // Filtros para "Calidad"
    const [calidadFilterPerito, setCalidadFilterPerito] = useState(null);  // Solo Admin
    const [calidadFilterSearch, setCalidadFilterSearch] = useState('');
    const [calidadFilterProv, setCalidadFilterProv] = useState('');

    //Almacena el numero de tareas pendientes
    const [pendingNotificacionesCount, setPendingNotificacionesCount] = useState(0);

    //Estados para abrir el modal de detalles del expediente en el control de calidad
    const [isExpedienteDetailModalVisible, setIsExpedienteDetailModalVisible] = useState(false);
    const [selectedProcedimientoDetailId, setSelectedProcedimientoDetailId] = useState(null);


    // -------------------------------
    // 1. CARGA INFO DEL USUARIO
    // -------------------------------
    useEffect(() => {
        axiosInstance.get('/mi_usuario/')
            .then(response => {
                setUserRole(response.data.role);
            })
            .catch(error => {
                console.error('Error al obtener info del usuario:', error);
            });
    }, []);

    // 1.b. Cargar lista de peritos si userRole es Admin
    useEffect(() => {
        if (userRole === 'Admin') {
            axiosInstance.get('usuarios/')
                .then(response => {
                    const peritosData = response.data.filter(u => u.role === 'Perito');
                    setPeritos(peritosData);
                })
                .catch(error => {
                    console.error('Error al obtener peritos:', error);
                });
        }
    }, [userRole]);

    // -------------------------------
    // 2. CAMBIO DE PESTAÑA => FETCH
    // -------------------------------
    useEffect(() => {
        if (selectedKey === 'mis-facturas') {
            fetchMisFacturas(); // sin filtros al inicio
        } else if (selectedKey === 'nueva-factura') {
            fetchPendientesParaFormulario();
        } else if (selectedKey === 'pendientes') {
            fetchPendientesFacturar();
        } else if (selectedKey === 'calidad') {
            fetchProcedimientosCalidad();
        }
    }, [selectedKey]);

    // -------------------------------
    // 3. FILTRAR AUTOMÁTICAMENTE "MIS FACTURAS"
    //    Cada vez que cambie un filtro => se dispara fetch
    // -------------------------------
    useEffect(() => {
        if (selectedKey === 'mis-facturas') {
            // Convertimos dateRange a fecha_inicio / fecha_fin
            let fecha_inicio = null;
            let fecha_fin = null;
            if (filterDateRange.length === 2) {
                fecha_inicio = filterDateRange[0].format('YYYY-MM-DD');
                fecha_fin = filterDateRange[1].format('YYYY-MM-DD');
            }
            const filters = {
                perito: filterPerito || '',
                procedimiento: filterProcedimiento || '',
                numero_factura: filterNumeroFactura || '',
                estado_factura: filterEstadoFactura || '',
                fecha_inicio: fecha_inicio || '',
                fecha_fin: fecha_fin || '',
            };
            fetchMisFacturas(filters);
        }
    }, [
        filterPerito,
        filterProcedimiento,
        filterNumeroFactura,
        filterDateRange,
        filterEstadoFactura,
        selectedKey
    ]);

    useEffect(() => {
        axiosInstance.get('provincias/')  // asumiendo GET /provincias/
            .then(resp => {
                setProvinces(resp.data);  // resp.data debe ser un array de {id, nombre}
            })
            .catch(err => console.error('Error al cargar provincias:', err));
    }, []);

    // -------------------------------
    // FUNCIONES DE FETCH
    // -------------------------------
    const fetchMisFacturas = async (filters = {}) => {
        try {
            // Construimos query string
            const queryParams = new URLSearchParams();
            if (filters.perito) queryParams.append('perito', filters.perito);
            if (filters.procedimiento) queryParams.append('procedimiento', filters.procedimiento);
            if (filters.numero_factura) queryParams.append('numero_factura', filters.numero_factura);
            if (filters.estado_factura) queryParams.append('estado_factura', filters.estado_factura);
            if (filters.fecha_inicio) queryParams.append('fecha_inicio', filters.fecha_inicio);
            if (filters.fecha_fin) queryParams.append('fecha_fin', filters.fecha_fin);

            let url = 'facturas-perito/mis_facturas_light/';
            if (queryParams.toString()) {
                url += `?${queryParams.toString()}`;
            }

            const response = await axiosInstance.get(url);
            setMisFacturas(response.data);
        } catch (error) {
            console.error('Error al cargar mis facturas:', error);
            message.error('No se pudieron cargar tus facturas');
        }
    };

    const fetchPendientesParaFormulario = async (selectedPeritoId = null) => {
        try {
            let url = 'facturas-perito/pendientes_facturar/';
            if (selectedPeritoId) {
                url += `?perito=${selectedPeritoId}`;
            }
            const response = await axiosInstance.get(url);
            setProcedimientosDisponibles(response.data);
        } catch (error) {
            console.error('Error al obtener procedimientos disponibles:', error);
        }
    };

    const estadoMap = {
        solicitado: 'Solicitado',
        asignado_peritos: 'Asignado Peritos',
        informe_entregado: 'Informe Entregado',
        pendiente_descarga: 'Pendiente Descarga',
        informe_descargado: 'Informe Descargado',
        anulado: 'Anulado',
        incomparecencia: 'Incomparecencia',
    };

    const fetchPendientesFacturar = async () => {
        try {
            const response = await axiosInstance.get('facturas-perito/pendientes_facturar/');
            setPendientes(response.data);
        } catch (error) {
            console.error('Error al cargar pendientes de facturar:', error);
        }
    };

    const fetchProcedimientosCalidad = async () => {
        try {
            let url = 'procedimientos/calidad/';
            if (userRole !== 'Admin') {
                url += '?perito=true';
            }
            const response = await axiosInstance.get(url);
            setProcedimientosCalidad(response.data);
        } catch (error) {
            console.error('Error al cargar procedimientos de calidad:', error);
            message.error('No se pudieron cargar los procedimientos pendientes de control de calidad');
        }
    };

    //Función para abrir el modal de detalles del expediente en el control de calidad
    const openExpedienteDetailModal = (procedimientoId) => {
        setSelectedProcedimientoDetailId(procedimientoId);
        setIsExpedienteDetailModalVisible(true);
    };


    useEffect(() => {
        if (selectedKey === 'pendientes') {
            const query = new URLSearchParams();

            // Si es admin y se eligió un perito
            if (userRole === 'Admin' && pendFilterPerito) {
                query.set('perito', pendFilterPerito);
            }
            // Filtro general
            if (pendFilterSearch.trim()) {
                query.set('search', pendFilterSearch.trim());
            }
            // Filtro de provincia
            if (pendFilterProv) {
                query.set('provincia', pendFilterProv);
            }

            let url = 'facturas-perito/pendientes_facturar/';
            if (query.toString()) {
                url += `?${query.toString()}`;
            }

            axiosInstance.get(url)
                .then(resp => {
                    setPendientes(resp.data);
                })
                .catch(err => {
                    console.error('Error filtrando pendientes:', err);
                    message.error('No se pudieron cargar los pendientes con filtros.');
                });
        }
    }, [selectedKey, pendFilterPerito, pendFilterSearch, pendFilterProv, userRole]);

    useEffect(() => {
        if (selectedKey === 'calidad') {
            const query = new URLSearchParams();

            // Filtro general (id, juzgado, procedimiento):
            if (calidadFilterSearch.trim()) {
                query.set('search', calidadFilterSearch.trim());
            }
            // Filtro por provincia
            if (calidadFilterProv) {
                query.set('provincia', calidadFilterProv);
            }
            // Filtro por perito (solo Admin)
            if (userRole === 'Admin' && calidadFilterPerito) {
                query.set('perito', calidadFilterPerito);
            }

            let url = 'procedimientos/calidad/';
            if (query.toString()) {
                url += `?${query.toString()}`;
            }

            axiosInstance.get(url)
                .then(resp => {
                    setProcedimientosCalidad(resp.data);
                })
                .catch(err => {
                    console.error('Error filtrando calidad:', err);
                    message.error('No se pudieron cargar los procedimientos de calidad con filtros.');
                });
        }
    }, [selectedKey, calidadFilterSearch, calidadFilterProv, calidadFilterPerito, userRole]);



    // -------------------------------
    // EXPORTAR A EXCEL
    // -------------------------------
    const handleExportExcelMisFacturas = () => {
        if (!misFacturas || misFacturas.length === 0) {
            message.warning('No hay datos para exportar');
            return;
        }
        const dataForExcel = misFacturas.map(f => ({
            'Nº Factura': f.numero_factura,
            'Fecha Emisión': f.fecha_emision
                ? dayjs(f.fecha_emision).format('DD-MM-YYYY')
                : '',
            'Procedimientos': f.procedimientos
                ? f.procedimientos.map(proc => proc.id).join(', ')
                : '',
            'Perito': f.perito
                ? `${f.perito.nombre} ${f.perito.apellidos}`
                : '',
            'Estado': f.estado_factura
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'MisFacturas');
        XLSX.writeFile(wb, 'MisFacturas.xlsx');
    };

    // -------------------------------
    // ACTIONS: MIS FACTURAS
    // -------------------------------
    const openInvoiceEditModal = (record) => {
        setSelectedInvoice(record);
        formInvoiceEdit.setFieldsValue({
            estado_factura: record.estado_factura,
        });
        setIsInvoiceEditModalVisible(true);
    };

    const handleUpdateInvoiceState = async (invoice, values) => {
        try {
            await axiosInstance.patch(`facturas-perito/${invoice.id}/`, {
                estado_factura: values.estado_factura,
            });
            message.success('Estado actualizado correctamente');
            setIsInvoiceEditModalVisible(false);
            // Refrescar la lista
            fetchMisFacturas();
        } catch (error) {
            console.error('Error al actualizar el estado:', error);
            message.error('No se pudo actualizar el estado');
        }
    };

    const handleDownloadInvoice = (record) => {
        if (record.archivo_factura_url) {
            window.open(record.archivo_factura_url, '_blank');
        } else {
            message.error('No hay archivo disponible para descargar');
        }
    };

    const openInvoiceIncidenceModal = (record) => {
        setSelectedInvoiceIncidence(record);
        formInvoiceIncidence.resetFields();
        setIsInvoiceIncidenceModalVisible(true);
    };

    const handleSendInvoiceIncidence = async (values) => {
        try {
            const payload = {
                motivo_incidencia: values.motivo_incidencia,
            };
            await axiosInstance.post(`facturas-perito/${selectedInvoiceIncidence.id}/notificar_incidencia/`, payload);
            message.success('Incidencia de facturación enviada correctamente');
            setIsInvoiceIncidenceModalVisible(false);
        } catch (error) {
            console.error('Error al enviar incidencia de facturación:', error);
            message.error('No se pudo enviar la incidencia de facturación');
        }
    };

    const handleExportExcelCalidad = () => {
        if (!procedimientosCalidad || procedimientosCalidad.length === 0) {
            message.warning('No hay datos para exportar');
            return;
        }

        // Mapeamos a un array de objetos 'planos' para XLSX:
        const dataForExcel = procedimientosCalidad.map(proc => ({
            'ID': proc.id,
            'Nº Procedimiento': proc.numero_procedimiento || '',
            'Juzgado': proc.juzgado_nombre || '',
            'Provincia': proc.provincia_nombre || '',
            'Peritos Asignados': proc.peritos_asignados_nombres || '',
            'Estado': proc.estado || '',
            'Calidad': proc.control_calidad || '',
        }));

        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Calidad');
        XLSX.writeFile(wb, 'ProcedimientosCalidad.xlsx');
    };


    // -------------------------------
    // ACTIONS: CALIDAD
    // -------------------------------
    const openQualityIncidenceModal = (record) => {
        setSelectedQualityProc(record);
        formIncidence.resetFields();
        setIsIncidenceModalVisible(true);
    };

    const handleSendQualityIncidence = async (values) => {
        try {
            const payload = {
                incidencia_text: values.incidence_text
            };
            await axiosInstance.post(`procedimientos/${selectedQualityProc.id}/notificar_incidencia_calidad/`, payload);
            message.success('Incidencia de calidad enviada correctamente');
            setIsIncidenceModalVisible(false);
        } catch (error) {
            console.error('Error al enviar incidencia de calidad:', error);
            message.error('No se pudo enviar la incidencia de calidad');
        }
    };

    const openAdminQualityModal = (record) => {
        setSelectedQualityProc(record);
        formAdminQuality.setFieldsValue({
            control_calidad: record.control_calidad,
        });
        setIsAdminQualityModalVisible(true);
    };

    const handleUpdateQualityControl = async (values) => {
        try {
            await axiosInstance.patch(`procedimientos/${selectedQualityProc.id}/`, {
                control_calidad: values.control_calidad,
            });
            message.success('Control de calidad actualizado correctamente');
            setIsAdminQualityModalVisible(false);
            fetchProcedimientosCalidad();
        } catch (error) {
            console.error('Error al actualizar control de calidad:', error);
            message.error('No se pudo actualizar el control de calidad');
        }
    };

    //Función para calcular el número de notificaciones pendientes
    const fetchPendingNotificacionesCount = async () => {
        try {
            const response = await axiosInstance.get('/notificaciones/');
            // Se filtran aquellas tareas cuya propiedad estado_tarea sea "pendiente"
            const pendingCount = response.data.filter(n => n.estado_tarea === 'pendiente').length;
            setPendingNotificacionesCount(pendingCount);
        } catch (error) {
            console.error("Error al obtener el número de notificaciones pendientes:", error);
        }
    };

    useEffect(() => {
        if (userRole === 'Admin') {
            fetchPendingNotificacionesCount();
        }
    }, [userRole]);

    // -------------------------------
    // COLUMNAS DE TABLAS
    // -------------------------------
    const columnsMisFacturas = [
        {
            title: 'N.º Factura',
            dataIndex: 'numero_factura',
            key: 'numero_factura',
        },
        {
            title: 'Fecha Emisión',
            dataIndex: 'fecha_emision',
            key: 'fecha_emision',
            render: (text) => (text ? dayjs(text).format('DD-MM-YYYY') : ''),
        },
        {
            title: 'Procedimientos (IDs)',
            dataIndex: 'procedimientos_ids',
            key: 'procedimientos_ids',
            render: (arr) => arr.join(', '),
        },
        {
            title: 'Perito',
            dataIndex: 'perito_nombre',
            key: 'perito_nombre',
        },
        {
            title: 'Estado',
            dataIndex: 'estado_factura',
            key: 'estado_factura',
            render: (text) => {
                if (text === 'enviada') return 'Enviada';
                if (text === 'aceptada') return 'Aceptada';
                if (text === 'rechazada') return 'Rechazada';
                return text;
            },
        },
        {
            title: 'Acciones',
            key: 'acciones',
            render: (record) => (
                <div>
                    <DownloadOutlined
                        onClick={() => handleDownloadInvoice(record)}
                        style={{ marginRight: 8, cursor: 'pointer' }}
                        title="Descargar Factura"
                    />
                    <ExclamationCircleOutlined
                        onClick={() => openInvoiceIncidenceModal(record)}
                        style={{ marginRight: 8, cursor: 'pointer' }}
                        title="Reportar Incidencia de Facturación"
                    />
                    {userRole === 'Admin' && (
                        <EditOutlined
                            onClick={() => openInvoiceEditModal(record)}
                            style={{ cursor: 'pointer' }}
                            title="Modificar Estado de Factura"
                        />
                    )}
                </div>
            ),
        }
    ];

    const columnsPendientes = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Juzgado',
            dataIndex: 'juzgado_nombre',
            key: 'juzgado_nombre',
        },
        {
            title: 'N.º Procedimiento',
            dataIndex: 'numero_procedimiento',
            key: 'numero_procedimiento',
        },
        {
            title: 'Provincia',
            dataIndex: 'provincia_nombre',
            key: 'provincia_nombre',
        },
        {
            title: 'Peritos Asignados',         // <--- NUEVA COLUMNA
            dataIndex: 'peritos_asignados_nombres',
            key: 'peritos_asignados_nombres',
        },
        {
            title: 'Fecha Entrega',
            dataIndex: 'fecha_entrega_informe',
            key: 'fecha_entrega_informe',
            render: (val) => (val ? dayjs(val).format('DD-MM-YYYY HH:mm') : ''),
        },
        {
            title: 'N.º Miembros',
            dataIndex: 'numero_miembros_evaluados',
            key: 'numero_miembros_evaluados',
        },
    ];



    const columnsCalidad = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'N.º Procedimiento',
            dataIndex: 'numero_procedimiento',
            key: 'numero_procedimiento',
        },
        {
            title: 'Juzgado',
            dataIndex: 'juzgado_nombre',
            key: 'juzgado_nombre',
        },
        {
            title: 'Provincia',
            dataIndex: 'provincia_nombre',
            key: 'provincia_nombre',
        },
        {
            title: 'Peritos Asignados',
            dataIndex: 'peritos_asignados_nombres',
            key: 'peritos_asignados_nombres',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (val) => estadoMap[val] || val,
        },
        {
            title: 'Calidad',
            dataIndex: 'control_calidad',
            key: 'control_calidad',
        },
        {
            title: 'Acciones',
            key: 'acciones',
            render: (record) => (
                <div>
                    <ExclamationCircleOutlined
                        onClick={() => openQualityIncidenceModal(record)}
                        style={{ marginRight: 8, cursor: 'pointer' }}
                        title="Reportar Incidencia Calidad"
                    />
                    <EyeOutlined
                        onClick={() => openExpedienteDetailModal(record.id)}
                        style={{ marginRight: 8, cursor: 'pointer' }}
                        title="Ver Detalles del Expediente"
                    />
                    {userRole === 'Admin' && (
                        <EditOutlined
                            onClick={() => openAdminQualityModal(record)}
                            style={{ cursor: 'pointer' }}
                            title="Modificar Control de Calidad"
                        />
                    )}
                </div>
            ),
        },
    ];


    // -------------------------------
    // CREAR FACTURA (SECCIÓN "Nueva Factura")
    // -------------------------------
    const onFinishNuevaFactura = async (values) => {
        try {
            const formData = new FormData();
            formData.append('numero_factura', values.numero_factura);
            formData.append(
                'fecha_emision',
                values.fecha_emision ? values.fecha_emision.format('YYYY-MM-DD') : ''
            );
            values.procedimientos.forEach(pid => {
                formData.append('procedimientos', pid);
            });

            // Si es Admin y selecciona un perito
            if (userRole === 'Admin' && values.perito) {
                formData.append('perito', values.perito);
            }
            // Archivo
            if (values.archivo_factura) {
                formData.append('archivo_factura', values.archivo_factura);
            }

            const response = await axiosInstance.post(
                'facturas-perito/crear_factura/',
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            if (
                response.status === 207 &&
                response.data.procedimientos_no_validos
            ) {
                message.warning(
                    `Algunos procedimientos no cumplen los requisitos: ${response.data.procedimientos_no_validos.join(', ')}`
                );
            } else {
                message.success('Factura enviada correctamente');
            }
            formFactura.resetFields();
            // Refrescamos "Mis Facturas"
            fetchMisFacturas();
        } catch (error) {
            console.error('Error al crear factura:', error);
            if (error.response && error.response.data) {
                if (
                    error.response.status === 207 &&
                    error.response.data.procedimientos_no_validos
                ) {
                    message.warning(
                        `Algunos procedimientos no cumplen los requisitos: ${error.response.data.procedimientos_no_validos.join(', ')}`
                    );
                } else if (error.response.data.error) {
                    message.error(error.response.data.error);
                }
            } else {
                message.error('No se pudo crear la factura');
            }
        }
    };

    // -------------------------------
    // RENDER
    // -------------------------------
    return (
        <>
            <Header />
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
                    <Menu
                        theme="dark"
                        selectedKeys={[selectedKey]}
                        onClick={(e) => setSelectedKey(e.key)}
                        mode="inline"
                        style={{ marginTop: '50px' }}
                    >
                        <Menu.Item key="portal-privado">Portal Privado</Menu.Item>
                        <Menu.Item key="procedimientos">Procedimientos</Menu.Item>
                        <Menu.SubMenu key="peritos" title="Peritos">
                            <Menu.Item key="mis-facturas">Facturas</Menu.Item>
                            <Menu.Item key="nueva-factura">Nueva Factura</Menu.Item>
                            <Menu.Item key="pendientes">Pendientes de Facturar</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.Item key="calidad">Calidad</Menu.Item>
                        <Menu.Item key="calendario">Calendario</Menu.Item>
                        {userRole === 'Admin' && (
                            <Menu.SubMenu key="administracion" title="Administración">
                                <Menu.Item key="facturas-admin">Facturas</Menu.Item>
                                <Menu.Item key="facturas-admin-new">Añadir Factura</Menu.Item>
                                <Menu.Item key="pdts-factura-admin">Pdts Facturar</Menu.Item>
                                <Menu.Item key="informes">Informes</Menu.Item>
                            </Menu.SubMenu>
                        )}
                        {userRole === 'Admin' && (
                            <Menu.Item key="notificaciones">
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                Notificaciones
                                <Badge count={pendingNotificacionesCount} style={{ backgroundColor: '#f5222d', marginLeft: '8px' }} />
                              </span>
                            </Menu.Item>

                        )}
                    </Menu>
                </Sider>

                <Layout>
                    <Content style={{ padding: '24px' }}>
                        {/* SECCIÓN "MIS FACTURAS" */}
                        {selectedKey === 'mis-facturas' && (
                            <>
                                <h2>Mis Facturas</h2>
                                {/* Filtros automáticos */}
                                <div style={{
                                    marginBottom: 16,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '1rem',
                                    alignItems: 'center'
                                }}>
                                    {userRole === 'Admin' && (
                                        <Select
                                            placeholder="Filtrar por Perito"
                                            allowClear
                                            value={filterPerito}
                                            onChange={(value) => setFilterPerito(value)}
                                            style={{ width: 220 }}
                                        >
                                            {peritos.map(p => (
                                                <Option key={p.id} value={p.id}>
                                                    {`${p.nombre} ${p.apellidos}`}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}

                                    <Input
                                        placeholder="ID Procedimiento"
                                        value={filterProcedimiento}
                                        onChange={(e) => setFilterProcedimiento(e.target.value)}
                                        style={{ width: 180 }}
                                    />
                                    <Input
                                        placeholder="Nº Factura"
                                        value={filterNumeroFactura}
                                        onChange={(e) => setFilterNumeroFactura(e.target.value)}
                                        style={{ width: 180 }}
                                    />
                                    <RangePicker
                                        format="DD-MM-YYYY"
                                        value={filterDateRange}
                                        onChange={(range) => setFilterDateRange(range || [])}
                                    />
                                    <Select
                                        placeholder="Estado Factura"
                                        allowClear
                                        style={{ width: 180 }}
                                        value={filterEstadoFactura}
                                        onChange={(val) => setFilterEstadoFactura(val)}
                                    >
                                        <Option value="enviada">Enviada</Option>
                                        <Option value="aceptada">Aceptada</Option>
                                        <Option value="rechazada">Rechazada</Option>
                                    </Select>

                                    <Button
                                        icon={<DownloadOutlined />}
                                        onClick={handleExportExcelMisFacturas}
                                    >
                                        Exportar Excel
                                    </Button>
                                </div>

                                {/* Tabla de Facturas */}
                                <Table
                                    columns={columnsMisFacturas}
                                    dataSource={misFacturas}
                                    rowKey="id"
                                />
                            </>
                        )}

                        {/* SECCIÓN "NUEVA FACTURA" */}
                        {selectedKey === 'nueva-factura' && (
                            <>
                                <h2>Nueva Factura</h2>
                                <Form form={formFactura} layout="vertical" onFinish={onFinishNuevaFactura}>
                                    <Form.Item
                                        label="Número de Factura"
                                        name="numero_factura"
                                        rules={[{ required: true, message: 'Ingresa el número de factura' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Fecha de Emisión"
                                        name="fecha_emision"
                                        rules={[{ required: true, message: 'Selecciona la fecha de emisión' }]}
                                    >
                                        <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                                    </Form.Item>
                                    {userRole === 'Admin' && (
                                        <Form.Item
                                            label="Perito"
                                            name="perito"
                                            rules={[{ required: true, message: 'Selecciona el perito' }]}
                                        >
                                            <Select
                                                placeholder="Selecciona un perito"
                                                onChange={(value) => {
                                                    fetchPendientesParaFormulario(value);
                                                }}
                                            >
                                                {peritos.map(p => (
                                                    <Option key={p.id} value={p.id}>
                                                        {`${p.nombre} ${p.apellidos}`}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    )}
                                    <Form.Item
                                        label="Procedimientos a Incluir"
                                        name="procedimientos"
                                        rules={[{ required: true, message: 'Selecciona al menos un procedimiento' }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Selecciona procedimientos pendientes"
                                            allowClear
                                        >
                                            {procedimientosDisponibles.map(proc => (
                                                <Option key={proc.id} value={proc.id}>
                                                    {`ID ${proc.id} - ${proc.numero_procedimiento} - ${proc.juzgado_nombre}`}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Archivo de la Factura (PDF, etc.)"
                                        name="archivo_factura"
                                        valuePropName="file"
                                        getValueFromEvent={(e) => {
                                            if (Array.isArray(e)) return e;
                                            return e?.fileList?.[0]?.originFileObj || null;
                                        }}
                                    >
                                        <Upload beforeUpload={() => false} maxCount={1}>
                                            <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Enviar Factura
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                        )}

                        {/* SECCIÓN "PENDIENTES DE FACTURAR" */}
                        {selectedKey === 'pendientes' && (
                            <>
                                <h2>Pendientes de Facturar</h2>

                                {/* Filtros para Pendientes */}
                                <div style={{ marginBottom: 16, display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                                    {/* Filtro por Perito (solo Admin) */}
                                    {userRole === 'Admin' && (
                                        <Select
                                            placeholder="Filtrar por Perito"
                                            allowClear
                                            value={pendFilterPerito}
                                            onChange={(val) => setPendFilterPerito(val)}
                                            style={{ width: 220 }}
                                        >
                                            {peritos.map(p => (
                                                <Option key={p.id} value={p.id}>
                                                    {`${p.nombre} ${p.apellidos}`}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}

                                    {/* Filtro de búsqueda general (ID, juzgado, nº procedimiento) */}
                                    <Input
                                        placeholder="Buscar (Proc, ID, Juzgado)"
                                        value={pendFilterSearch}
                                        onChange={(e) => setPendFilterSearch(e.target.value)}
                                        style={{ width: 220 }}
                                    />

                                    {/* Filtro de Provincia */}
                                    <Select
                                        placeholder="Provincia"
                                        allowClear
                                        value={pendFilterProv}
                                        onChange={(val) => setPendFilterProv(val)}
                                        style={{ width: 180 }}
                                    >
                                        {/* Llenamos con las provincias obtenidas del backend */}
                                        {provinces.map((prov) => (
                                            <Option key={prov.id} value={prov.nombre}>
                                                {prov.nombre}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>

                                {/* Tabla de Pendientes */}
                                <Table
                                    columns={columnsPendientes}
                                    dataSource={pendientes}
                                    rowKey="id"
                                />
                            </>
                        )}

                        {/* SECCIÓN "CALIDAD" */}
                        {selectedKey === 'calidad' && (
                            <>
                                <h2>Control de Calidad</h2>
                                {/* Filtros */}
                                <div style={{ marginBottom: 16, display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                                    {/* Filtro por perito (solo Admin) */}
                                    {userRole === 'Admin' && (
                                        <Select
                                            placeholder="Filtrar por Perito"
                                            allowClear
                                            value={calidadFilterPerito}
                                            onChange={val => setCalidadFilterPerito(val)}
                                            style={{ width: 220 }}
                                        >
                                            {peritos.map(p => (
                                                <Option key={p.id} value={p.id}>
                                                    {`${p.nombre} ${p.apellidos}`}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}

                                    {/* Filtro de búsqueda general (ID, Juzgado, Procedimiento) */}
                                    <Input
                                        placeholder="Buscar (Proc, ID, Juzgado)"
                                        value={calidadFilterSearch}
                                        onChange={(e) => setCalidadFilterSearch(e.target.value)}
                                        style={{ width: 220 }}
                                    />

                                    {/* Filtro de provincia */}
                                    <Select
                                        placeholder="Provincia"
                                        allowClear
                                        value={calidadFilterProv}
                                        onChange={(val) => setCalidadFilterProv(val)}
                                        style={{ width: 180 }}
                                    >
                                        {provinces.map((prov) => (
                                            <Option key={prov.id} value={prov.nombre}>
                                                {prov.nombre}
                                            </Option>
                                        ))}
                                    </Select>

                                    {/* Botón para Exportar a Excel */}
                                    <Button
                                        icon={<DownloadOutlined />}
                                        onClick={handleExportExcelCalidad}
                                    >
                                        Exportar Excel
                                    </Button>
                                </div>

                                {/* Tabla */}
                                <Table
                                    columns={columnsCalidad}
                                    dataSource={procedimientosCalidad}
                                    rowKey="id"
                                />
                            </>
                        )}


                        {/* SECCIONES DE ADMINISTRACIÓN */}
                        {selectedKey === 'facturas-admin' && userRole === 'Admin' && (
                            <FacturaAdminList />
                        )}
                        {selectedKey === 'facturas-admin-new' && userRole === 'Admin' && (
                            <FacturaAdminCreate />
                        )}
                        {selectedKey === 'pdts-factura-admin' && userRole === 'Admin' && (
                            <PdtsFacturarList />
                        )}
                        {selectedKey === 'calendario' && (
                            <CalendarioPage />
                        )}
                        {selectedKey === 'portal-privado' && (
                            <PrivatePortalDashboard
                                onGoCalendario={() => setSelectedKey('calendario')}
                            />
                        )}
                        {selectedKey === 'informes' && userRole === 'Admin' && (
                            <InformesEstadisticos />
                        )}
                        {selectedKey === 'notificaciones' && userRole === 'Admin' && (
                            <Notificaciones />
                        )}
                        {selectedKey === 'procedimientos' && <ListadoProcedimientos />}
                    </Content>
                </Layout>
            </Layout>

            {/* MODAL: Reportar Incidencia de Calidad */}
            <Modal
                title="Reportar Incidencia Calidad"
                visible={isIncidenceModalVisible}
                onCancel={() => setIsIncidenceModalVisible(false)}
                onOk={() => {
                    formIncidence.validateFields()
                        .then(values => {
                            handleSendQualityIncidence(values);
                        })
                        .catch(info => {
                            console.log('Error en validación de incidencia de calidad:', info);
                        });
                }}
                okText="Enviar Incidencia"
                cancelText="Cancelar"
            >
                <Form form={formIncidence} layout="vertical">
                    <Form.Item
                        name="incidence_text"
                        label="Detalle de la Incidencia"
                        rules={[{ required: true, message: 'Ingresa el detalle de la incidencia' }]}
                    >
                        <Input.TextArea rows={4} placeholder="Describe la incidencia de calidad..." />
                    </Form.Item>
                </Form>
            </Modal>

            {/* MODAL: Modificar Control de Calidad (Admin) */}
            <Modal
                title="Modificar Control de Calidad"
                visible={isAdminQualityModalVisible}
                onCancel={() => setIsAdminQualityModalVisible(false)}
                onOk={() => {
                    formAdminQuality.validateFields()
                        .then(values => {
                            handleUpdateQualityControl(values);
                        })
                        .catch(info => {
                            console.log('Error en validación de control de calidad:', info);
                        });
                }}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form form={formAdminQuality} layout="vertical">
                    <Form.Item
                        name="control_calidad"
                        label="Nuevo Control de Calidad"
                        rules={[{ required: true, message: 'Selecciona un nuevo estado de control de calidad' }]}
                    >
                        <Select placeholder="Selecciona un estado">
                            <Option value="pendiente">Pendiente</Option>
                            <Option value="aprobado">Aprobado</Option>
                            <Option value="suspendido">Suspendido</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            {/* MODAL: Modificar Estado de Factura (Admin) */}
            <Modal
                title="Modificar Estado de Factura"
                visible={isInvoiceEditModalVisible}
                onCancel={() => setIsInvoiceEditModalVisible(false)}
                onOk={() => {
                    formInvoiceEdit.validateFields()
                        .then(values => {
                            handleUpdateInvoiceState(selectedInvoice, values);
                        })
                        .catch(info => {
                            console.log('Error en validación:', info);
                        });
                }}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form form={formInvoiceEdit} layout="vertical">
                    <Form.Item
                        name="estado_factura"
                        label="Nuevo Estado de Factura"
                        rules={[{ required: true, message: 'Selecciona un estado' }]}
                    >
                        <Select placeholder="Selecciona un estado">
                            <Option value="enviada">Enviada</Option>
                            <Option value="aceptada">Aceptada</Option>
                            <Option value="rechazada">Rechazada</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                visible={isExpedienteDetailModalVisible}
                title="Detalles del Expediente"
                onCancel={() => setIsExpedienteDetailModalVisible(false)}
                footer={null}
                width={1600}  // Ancho suficiente para ver los detalles
            >
                {selectedProcedimientoDetailId && (
                    <ExpedienteDetail procedimientoId={selectedProcedimientoDetailId} />
                )}
            </Modal>

            {/* MODAL: Notificar Incidencia en Facturación */}
            <Modal
                title="Notificar Incidencia en Facturación"
                visible={isInvoiceIncidenceModalVisible}
                onCancel={() => setIsInvoiceIncidenceModalVisible(false)}
                onOk={() => {
                    formInvoiceIncidence.validateFields()
                        .then(values => {
                            handleSendInvoiceIncidence(values);
                        })
                        .catch(info => {
                            console.log('Error en validación de incidencia de facturación:', info);
                        });
                }}
                okText="Enviar Incidencia"
                cancelText="Cancelar"
            >
                <Form form={formInvoiceIncidence} layout="vertical">
                    <Form.Item
                        name="motivo_incidencia"
                        label="Detalle de la Incidencia"
                        rules={[{ required: true, message: 'Ingresa el detalle de la incidencia' }]}
                    >
                        <Input.TextArea rows={4} placeholder="Describe la incidencia de facturación..." />
                    </Form.Item>
                </Form>
            </Modal>

        </>
    );
}

export default FacturacionPage;
