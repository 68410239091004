// MensajesProcedimientoModal.js
import React, { useEffect, useState, useRef } from 'react';
import { Modal, Input, Button, message } from 'antd';
import axiosInstance from '../services/axiosConfig';
import dayjs from 'dayjs';
import './MensajesProcedimientoModal.css';

function MensajesProcedimientoModal({ visible, onClose, procedimientoId }) {
    const [mensajes, setMensajes] = useState([]);
    const [nuevoMensaje, setNuevoMensaje] = useState('');
    const scrollRef = useRef(null);

    useEffect(() => {
        if (visible && procedimientoId) {
            // Cargar los mensajes del procedimiento
            fetchMensajes();
        }
    }, [visible, procedimientoId]);

    const fetchMensajes = async () => {
        if (!procedimientoId) return;
        try {
            const resp = await axiosInstance.get(
                `/mensajes-procedimiento/?procedimiento_id=${procedimientoId}`
            );
            setMensajes(resp.data);
            // Pequeño delay para que se pinte antes de hacer scroll
            setTimeout(() => scrollToBottom(), 0);
        } catch (error) {
            console.error('Error al cargar mensajes:', error);
            message.error('No se pudieron cargar los mensajes');
        }
    };

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    };

    const handleEnviar = async () => {
        if (!nuevoMensaje.trim()) return;
        try {
            const payload = {
                procedimiento: procedimientoId,
                texto: nuevoMensaje.trim(),
            };
            await axiosInstance.post('/mensajes-procedimiento/', payload);
            setNuevoMensaje('');
            fetchMensajes(); // recargar lista
        } catch (error) {
            console.error('Error al enviar mensaje:', error);
            message.error('No se pudo enviar el mensaje');
        }
    };

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            title="Mensajes del Procedimiento"
            width={700}
            bodyStyle={{ display: 'flex', flexDirection: 'column', height: '500px' }}
        >
            {/* Zona de mensajes */}
            <div
                className="chat-messages-container"
                ref={scrollRef}
                style={{
                    flex: 1,
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    padding: '8px',
                    marginBottom: '16px',
                    borderRadius: '4px',
                }}
            >
                {mensajes.map((msg) => (
                    <div key={msg.id} className="chat-message-item">
                        <div className="chat-message-header">
                            <strong>{msg.autor_nombre || 'Usuario'}</strong>
                            <span className="chat-message-date">
                {dayjs(msg.fecha_creacion).format('DD/MM/YYYY HH:mm')}
              </span>
                        </div>
                        <div className="chat-message-text">
                            {msg.texto}
                        </div>
                    </div>
                ))}
            </div>

            {/* Input para nuevo mensaje */}
            <div style={{ display: 'flex', gap: '8px' }}>
                <Input.TextArea
                    value={nuevoMensaje}
                    onChange={(e) => setNuevoMensaje(e.target.value)}
                    rows={2}
                    placeholder="Escribe tu mensaje..."
                    style={{ resize: 'none' }}
                />
                <Button type="primary" onClick={handleEnviar}>
                    Enviar
                </Button>
            </div>
        </Modal>
    );
}

export default MensajesProcedimientoModal;
