import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import axiosInstance from '../services/axiosConfig';

const NuevoProcedimientoModal = ({ visible, onClose, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    // Estados para sugerencias
    const [juzgados, setJuzgados] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);

    // Para controlar la búsqueda
    const [juzgadoSearch, setJuzgadoSearch] = useState('');
    const [especialidadSearch, setEspecialidadSearch] = useState('');

    // Carga inicial / filtrada para Juzgados
    const fetchJuzgados = async (searchValue = '', limit = 10) => {
        try {
            const res = await axiosInstance.get('juzgados/', {
                params: { search: searchValue, limit }
            });
            setJuzgados(res.data);
        } catch (error) {
            console.error('Error al cargar juzgados:', error);
        }
    };

    // Carga inicial / filtrada para Especialidades
    const fetchEspecialidades = async (searchValue = '', limit = 10) => {
        try {
            const res = await axiosInstance.get('especialidades/', {
                params: { search: searchValue, limit }
            });
            setEspecialidades(res.data);
        } catch (error) {
            console.error('Error al cargar especialidades:', error);
        }
    };

    // Efecto para cargar/filtrar Juzgados al cambiar juzgadoSearch
    useEffect(() => {
        fetchJuzgados(juzgadoSearch, 10);
    }, [juzgadoSearch]);

    // Efecto para cargar/filtrar Especialidades al cambiar especialidadSearch
    useEffect(() => {
        fetchEspecialidades(especialidadSearch, 10);
    }, [especialidadSearch]);

    // Al abrir el modal, reseteamos el form y cargamos top10
    useEffect(() => {
        if (visible) {
            form.resetFields();
            fetchJuzgados('', 10);
            fetchEspecialidades('', 10);
        }
    }, [visible, form]);

    // Acción principal al pulsar “Solicitar”
    const handleFinish = async (values) => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('procedimientos/', values);
            if (response.status === 201) {
                message.success('Procedimiento solicitado correctamente.');
                onSubmit(response.data);
                form.resetFields();
                onClose();
            }
        } catch (error) {
            console.error('Error al crear el procedimiento:', error);
            message.error('No se pudo solicitar el procedimiento.');
        } finally {
            setLoading(false);
        }
    };

    // Dispara onFinish al pulsar el botón “Solicitar”
    const handleSolicitarClick = () => {
        form.submit();
    };

    return (
        <Modal
            title="Nuevo Procedimiento"
            visible={visible}
            footer={null}   // Eliminamos el footer por defecto
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            destroyOnClose
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>

                <Form.Item
                    label="Juzgado"
                    name="juzgado"
                    rules={[{ required: true, message: 'Seleccione el juzgado' }]}
                >
                    <Select
                        showSearch
                        placeholder="Buscar juzgado..."
                        notFoundContent="Sin datos"
                        onSearch={(val) => setJuzgadoSearch(val)}
                        onFocus={() => {
                            if (!juzgadoSearch) {
                                fetchJuzgados('', 10);
                            }
                        }}
                        filterOption={false}
                        options={juzgados.map((j) => ({
                            value: j.id,
                            label: j.nombre
                        }))}
                    />
                </Form.Item>

                <Form.Item
                    label="Número de Procedimiento"
                    name="numero_procedimiento"
                    rules={[{ required: true, message: 'Ingrese el número de procedimiento' }]}
                >
                    <Input placeholder="Ej. Guarda y Custodia. 123/2025" />
                </Form.Item>

                <Form.Item
                    label="Especialidad"
                    name="especialidad"
                    rules={[{ required: true, message: 'Seleccione la especialidad' }]}
                >
                    <Select
                        showSearch
                        placeholder="Buscar especialidad..."
                        notFoundContent="Sin datos"
                        onSearch={(val) => setEspecialidadSearch(val)}
                        onFocus={() => {
                            if (!especialidadSearch) {
                                fetchEspecialidades('', 10);
                            }
                        }}
                        filterOption={false}
                        options={especialidades.map((e) => ({
                            value: e.id,
                            label: e.nombre
                        }))}
                    />
                </Form.Item>

                <Form.Item
                    label="Número de Episodio IML (Opc)"
                    name="numero_episodio_iml"
                >
                    <Input placeholder="Ej. EP22589724" />
                </Form.Item>

                <Form.Item label="Notas" name="notas">
                    <Input.TextArea rows={3} placeholder="Notas adicionales..." />
                </Form.Item>

                {/* Footer personalizado con CANCELAR a la izquierda y SOLICITAR a la derecha */}
                {/* Footer personalizado */}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* CANCELAR (izquierda) */}
                    <Button onClick={onClose}>Cancelar</Button>

                    {/* BOTÓN "Peritación Excepcional" */}
                    <Button
                        // style={{ background: 'orange', borderColor: 'orange', color: '#fff' }}
                        onClick={() => {
                            form.validateFields().then(async (values) => {
                                try {
                                    // 1) Crear Procedimiento normal
                                    const respProc = await axiosInstance.post('procedimientos/', values);
                                    if (respProc.status === 201) {
                                        const procedimientoCreado = respProc.data;
                                        // 2) Crear PeritacionExcepcional
                                        const respExc = await axiosInstance.post('peritaciones-excepcionales/', {
                                            procedimiento: procedimientoCreado.id,
                                            gratuita: false,
                                            fecha_envio_presupuesto: null,
                                            estado_presupuesto: '', // "Aceptado" / "Rechazado" / etc.
                                            fecha_aceptacion_presupuesto: null,
                                        });
                                        if (respExc.status === 201) {
                                            message.success('Procedimiento creado como Peritación Excepcional.');
                                            onSubmit(procedimientoCreado);
                                            form.resetFields();
                                            onClose();
                                        }
                                    }
                                } catch (err) {
                                    console.error('Error al crear Peritación Excepcional:', err);
                                    message.error('No se pudo crear el procedimiento como Peritación Excepcional');
                                }
                            });
                        }}
                    >
                        Solicitar P. Excepcional
                    </Button>

                    {/* BOTÓN SOLICITAR (derecha) */}
                    <Button type="primary" onClick={() => form.submit()} loading={loading}>
                        Solicitar
                    </Button>
                </div>

            </Form>
        </Modal>
    );
};

export default NuevoProcedimientoModal;
