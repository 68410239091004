import React, { useEffect, useState } from 'react';
import { Table, Input, Select, Row, Col, Modal, message } from 'antd';
import axiosInstance from '../services/axiosConfig';
import ExpedienteDetail from './ExpedienteDetail';

const { Option } = Select;

const estadoMap = {
    solicitado: 'Solicitado',
    asignado_peritos: 'Asignado Peritos',
    informe_entregado: 'Informe Entregado',
    pendiente_descarga: 'Pendiente Descarga',
    informe_descargado: 'Informe Descargado',
    anulado: 'Anulado',
    incomparecencia: 'Incomparecencia',
};

const ListadoProcedimientos = () => {
    // Estados para el listado y filtros
    const [procedimientos, setProcedimientos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    // Inicializamos estos filtros como undefined para que el placeholder se muestre desde el primer render
    const [provinciaFilter, setProvinciaFilter] = useState(undefined);
    const [especialidadFilter, setEspecialidadFilter] = useState(undefined);
    const [estadoFilter, setEstadoFilter] = useState(undefined);
    const [selectedProcedimientoId, setSelectedProcedimientoId] = useState(null);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
    const [provincias, setProvincias] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);

    // Función para obtener el listado de procedimientos según los filtros
    const fetchProcedimientos = async () => {
        setLoading(true);
        try {
            const params = {};
            if (search) params.search = search;
            if (provinciaFilter) params.provincia = provinciaFilter;
            if (especialidadFilter) params.especialidad = especialidadFilter;
            if (estadoFilter) params.estado = estadoFilter;
            // Siempre ordena por ID descendente
            params.ordering = '-id';
            const response = await axiosInstance.get('/procedimientos/listado-procedimientos/', { params });
            setProcedimientos(response.data);
        } catch (error) {
            console.error('Error al cargar procedimientos', error);
            message.error('Error al cargar procedimientos');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProcedimientos();
    }, [search, provinciaFilter, especialidadFilter, estadoFilter]);

    // Función para obtener las provincias (para el filtro)
    const fetchProvincias = async () => {
        try {
            const response = await axiosInstance.get('/provincias/');
            setProvincias(response.data);
        } catch (error) {
            console.error('Error al cargar provincias', error);
        }
    };

    // Función para obtener las especialidades (para el filtro)
    const fetchEspecialidades = async () => {
        try {
            const response = await axiosInstance.get('/especialidades/');
            setEspecialidades(response.data);
        } catch (error) {
            console.error('Error al cargar especialidades', error);
        }
    };

    useEffect(() => {
        fetchProvincias();
        fetchEspecialidades();
    }, []);

    // Definición de columnas de la tabla (sin columna de acciones)
    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (text) => estadoMap[text] || text,
        },
        { title: 'Especialidad', dataIndex: 'especialidad_nombre', key: 'especialidad_nombre' },
        { title: 'Juzgado', dataIndex: 'juzgado_nombre', key: 'juzgado_nombre' },
        { title: 'Nº de Procedimiento', dataIndex: 'numero_procedimiento', key: 'numero_procedimiento' },
        { title: 'Provincia', dataIndex: 'provincia_nombre', key: 'provincia_nombre' },
    ];

    return (
        <div style={{ padding: '16px' }}>
            <h2>Listado de Procedimientos</h2>
            <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                <Col>
                    <Input
                        placeholder="Buscar por ID, Juzgado o Nº de Procedimiento"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Col>
                <Col>
                    <Select
                        placeholder="Filtrar por Provincia"
                        style={{ width: 200 }}
                        allowClear
                        value={provinciaFilter}
                        onChange={(value) => setProvinciaFilter(value)}
                    >
                        {provincias.map((prov) => (
                            <Option key={prov.id} value={prov.nombre}>
                                {prov.nombre}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col>
                    <Select
                        placeholder="Filtrar por Especialidad"
                        style={{ width: 200 }}
                        allowClear
                        value={especialidadFilter}
                        onChange={(value) => setEspecialidadFilter(value)}
                    >
                        {especialidades.map((esp) => (
                            <Option key={esp.id} value={esp.nombre}>
                                {esp.nombre}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col>
                    <Select
                        placeholder="Filtrar por Estado"
                        style={{ width: 200 }}
                        allowClear
                        value={estadoFilter}
                        onChange={(value) => setEstadoFilter(value)}
                    >
                        {Object.keys(estadoMap).map((key) => (
                            <Option key={key} value={key}>
                                {estadoMap[key]}
                            </Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={procedimientos}
                rowKey="id"
                loading={loading}
                onRow={(record) => ({
                    onClick: () => {
                        setSelectedProcedimientoId(record.id);
                        setIsDetailModalVisible(true);
                    },
                })}
            />
            <Modal
                visible={isDetailModalVisible}
                title="Detalles del Expediente"
                onCancel={() => setIsDetailModalVisible(false)}
                footer={null}
                width={1600} // Ancho suficiente para mostrar todos los detalles
            >
                {selectedProcedimientoId && (
                    <ExpedienteDetail procedimientoId={selectedProcedimientoId} />
                )}
            </Modal>
        </div>
    );
};

export default ListadoProcedimientos;
