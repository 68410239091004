import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Platform from './components/Platform';
import ResumenEstadistico from './components/ResumenEstadistico';
import MiPerfil from './components/MiPerfil';
import PrivateRoute from './components/PrivateRoute';
import './App.css';
import PasswordResetRequest from './components/PasswordResetRequest';
import PasswordReset from './components/PasswordReset';
import FacturacionPage from './components/FacturacionPage';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';


function App() {
    return (
        <ConfigProvider locale={esES}>
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route
                        path="/plataforma"
                        element={
                            <PrivateRoute>
                                <Platform />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/resumen-estadistico"
                        element={
                            <PrivateRoute>
                                <ResumenEstadistico />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/mi-perfil"
                        element={
                            <PrivateRoute>
                                <MiPerfil />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/portal-privado"
                        element={
                            <PrivateRoute>
                                <FacturacionPage />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/password-reset-request" element={<PasswordResetRequest />} />
                    <Route path="/password-reset" element={<PasswordReset />} />
                </Routes>
            </Router>
        </ConfigProvider>
    );
}

export default App;